export function checkUrl(value: string): boolean {
  let result: boolean;

  const urlPattern = /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]*)?(\?[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]*)?(#[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]*)?$/;
  if (!urlPattern.test(value)) return false
  try {
    new URL(value);
    result = true;
  } catch (err) {
    result = false;
  }

  return result;
}
