import { ITheme } from './theme.interface';

// todo dark theme variables
export const themeDark: ITheme = {
  background: '#f0f0f0',
  colorBlack: '#1c1916',
  mainColor: 'transparent',
  colorWhite: '#ffffff',
  colorRed: '#E42222',
  colorGrey: '#dedede',
  colorDarkGrey: '#a0a0a0',
  colorGreenSuccess: '#19BD16',
  colorDarkGreen: '#226620',
  colorBlueDark: '#0500FF',
  colorPink: '#FF2B55',
  colorBlue:  '#017CF3',
  colorGreen: '#34C659',
  colorGreenDark: '#31BC55',
  colorGreenLight: '#35C650',
  colorOrange: '#FF9300',
  colorOrangeLight: '#FF8F04',
  colorBlueLight: '#38ABD7',
  colorRedLight: '#FF382E',
  colorPurple: '#A153CB',
  colorPurpleLight: '#BB6FC7',
  colorGray: '#8C8C8E',
  colorGrayLight: '#A0A0A0',
  colorYellow: '#FFCC02',

  // rgb
  colorRgbMain: '5, 0, 255',
  colorRgbBlack: 'rgb(28, 25, 22)',
  colorRgbWhite: 'rgb(255, 255, 255)',
  colorRgbRed: '228, 34, 34',

  gradientBlue:
    'linear-gradient(110deg,rgba(9, 5, 171, 1) 0%,rgba(7, 3, 213, 1) 50%,rgba(78, 76, 181, 1) 75%)',

  transitionDuration: '.3s',
  appMaxWidth: 750,
  appFooterHeight: 86,
  sidePadding: 16,
  defaultFont: 'Montserrat, sans-serif',
};
