import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IStoreCardRuleKeys {
  card: {};
  imgWrapper: {};
  image: {};
  textInfo: {};
  role: {};
  name: {};
  description: {};
}

interface IStoreCardProps {
  active: boolean
}

export type IStoreCardRules = IRuleFn<IStoreCardRuleKeys, IStoreCardProps>;

const storeCardRulesFn: IStoreCardRules = props => {
  const { background, mainColor, colorGrayLight } = props.theme;
  const { active } = props;

  return {
    card: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 2,
      ...padding(10, 12),
      background: background,
      border: `2px solid ${active? mainColor : background}`,
      borderRadius: 12,
    },
    imgWrapper: {
      width: 38,
      height: 38,
      flexShrink: 0,
      marginRight: 7,
    },
    image: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
    name: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 600,
      letterSpacing: '-0.4px',
      marginBottom: 2,
    },
    description: {
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 600,
      letterSpacing: '-0.4px',
      color: colorGrayLight
    },
    textInfo: {
      flexGrow: 1,
      '& p': {
        display: '-webkit-box!important',
        width: '100%',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    },
    role: {
      flexShrink: 0,
      fontSize: 12, 
      lineHeight: '14px',
      fontWeight: 600,
      letterSpacing: '-0.4px',
      display: 'flex',
      alignItems: 'center',
    }
  };
};

export const storeCardRules = createCachedStyle(storeCardRulesFn);
