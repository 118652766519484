import { FC, memo, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { StoreNumbersRules } from './StoreNumbers.style';
import Text from 'src/components/UI/Text/Text';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../routing/routeConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getProducts, productsState } from '../../redux/slices/productsSlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { PillSoon } from '../../components/UI/PillSoon/PillSoon';
import { Icon } from '../../components/UI/Icon/Icon'
import { togglesState } from '../../redux/slices/configSlice';

interface IProps {
  onClick: () => void;
}

export const StoreNumbers: FC<IProps> = memo(function StoreNumbers(props) {
  const { statisticsEnabled } = useAppSelector(togglesState);
  const { css, rules } = useStyle(StoreNumbersRules);
  const { onClick } = props;
  const dispatch = useAppDispatch();
  const { products, networkStatus } = useAppSelector(productsState);
  const { t } = useTranslation();


  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getProducts());
    }
  }, [dispatch, networkStatus]);

  const isProductListLoading =
    networkStatus === NetworkStatus.None || networkStatus === NetworkStatus.Loading;

  return (
    <div
      className={css.wrapper}
      onClick={statisticsEnabled ? onClick : undefined}
    >
      <Link className={css.card} to={RoutePath.Products}>
        <div className={css.text}>
          <Text
            mod="title"
            text={t('storeNumbers.products')}
            fontWeight={800}
            fontSize={14}
            lineHeight={18}
            extend={css.title}
          />
          {isProductListLoading ? (
            <Text
              mod="title"
              text={'loading...'}
              fontWeight={500}
              fontSize={14}
              lineHeight={14}
            />
          ) : (
            <div className={css.info}>
              <Text
                mod="title"
                text={String(products.length)}
                fontWeight={500}
                fontSize={24}
                lineHeight={24}
              />
              <Icon name='bag' properties={{ className: css.bagIcon }}/>
            </div>
          )}
        </div>
      </Link>
      <div className={css.card}>
        <div className={css.text}>
          <Text
            mod="title"
            text={t('storeNumbers.statistics')}
            fontWeight={800}
            fontSize={14}
            lineHeight={18}
            extend={css.title}
          />
          <div className={css.info}>
            <Text mod="title" text="0" fontWeight={500} fontSize={24} lineHeight={24}/>
            <Icon name="people" />
          </div>
        </div>
        {!statisticsEnabled && <PillSoon extend={{ root: rules.soon }}/>}
      </div>
    </div>
  );
});
