import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IStepRuleKeys {
  step: {};
  stepHeader: {};
  stepNumber: {};
  title: {};
}

interface IStepRuleProps {
  paddingBottom?: number
}

export type IStepRules = IRuleFn<IStepRuleKeys, IStepRuleProps>;

const stepRulesFn: IStepRules = props => {
  const { paddingBottom = 20 } = props

  return {
    step: {
      paddingBottom,
    },
    stepHeader: { ...padding(20, 0, 28) },
    stepNumber: {
      marginBottom: 12,
    },
    title: {
      marginBottom: 8,
    },
  };
};

export const stepRules = createCachedStyle(stepRulesFn);
