import { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalSettingSelectionRules } from './ModalSettingSelection.style';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from 'src/components/UI/Button/Button';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { Input } from 'src/components/UI/Input/Input';
import { ISelectionProduct, ISelectionWithProducts } from 'src/types/selection';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import ModalListProducts from '../../../../components/ModalListProducts/ModalListProducts';
import { useModalState } from 'src/hooks/useModalState';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { productToSelectionBind } from 'src/redux/api/selections/productToSelectionBind';
import { addProductsToSelection, selectionsState } from 'src/redux/slices/selectionsSlice';
import { SubmitHandler, useForm } from 'react-hook-form';
import { createSelection } from 'src/redux/api/selections/createSelection';
import { editSelection } from 'src/redux/api/selections/editSelection';
import { useScrollModal } from 'src/hooks/useScrollModal';
import { selectionDelete } from 'src/redux/api/selections/selectionDelete';
import { IProduct } from 'src/types/product';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  active: boolean;
  onClose: () => void;
  actionType: 'editing' | 'creating';
  selection?: ISelectionWithProducts;
}

interface IFormSelection {
  selectionTitle: string;
}

export const ModalSettingSelection: FC<IProps> = memo(function ModalSettingSelection(props) {
  const { active, selection, actionType, onClose } = props;
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const dispatch = useAppDispatch();
  const { saveNetworkStatus } = useAppSelector(selectionsState);
  const formRef = useRef(null);
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);
  const { t, tPlural } = useTranslation();
  useScrollModal(modalInnerRef);

  const initialStateRef = useRef({
    uuid: '',
    title: '',
    visibility: {
      showInRecommendations: false,
      showOnMain: false,
    },
    products: [],
  });

  const [selectionData, setSelectionData] = useState<ISelectionWithProducts>(
    initialStateRef.current,
  );
  const [currentlySelectedProducts, setCurrentlySelectedProducts] = useState<ISelectionProduct[]>(
    [],
  );
  const productUuids = currentlySelectedProducts.map(el => el.uuid);

  useEffect(() => {
    if (selection) {
      setSelectionData(selection);
    } else {
      setSelectionData(initialStateRef.current);
    }
  }, [selection]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectionTitle: selection?.title || '',
    },
  });

  const onSubmit: SubmitHandler<IFormSelection> = data => {
    const { selectionTitle } = data;

    const selectionRequestData = { ...selectionData, title: selectionTitle };

    if (actionType === 'creating') {
      dispatch(createSelection(selectionRequestData)).then(res => {
        const result = res.payload as ISelectionWithProducts;
        dispatch(
          productToSelectionBind({
            selection: {
              ...selectionRequestData,
              uuid: result.uuid,
            },
            productUuids,
          }),
        );
        onClose();
      });
      return;
    }
    if (actionType === 'editing') {
      dispatch(editSelection(selectionRequestData)).then(() => onClose());
    }
  };

  function onClickDeleteSelection() {
    dispatch(selectionDelete(selectionData)).then(() => onClose());
  }

  function onClickProduct(event) {
    const product: IProduct = JSON.parse(event.currentTarget.dataset.product);
    const selectionProduct: ISelectionProduct = {
      uuid: product.id,
      previewImageUrls: product.imageUrls,
    };

    if (currentlySelectedProducts.some(el => el.uuid === selectionProduct.uuid)) {
      setCurrentlySelectedProducts(
        [...currentlySelectedProducts].filter(el => el.uuid !== selectionProduct.uuid),
      );
    } else {
      setCurrentlySelectedProducts([...currentlySelectedProducts, selectionProduct]);
    }
  }

  function changeShowOnMain(event) {
    const checked = event.currentTarget.checked;
    setSelectionData({
      ...selectionData,
      visibility: {
        showOnMain: checked,
        showInRecommendations: selectionData.visibility.showInRecommendations,
      },
    });
  }

  function changeShowInRecommendations(event) {
    const checked = event.currentTarget.checked;
    setSelectionData({
      ...selectionData,
      visibility: {
        showOnMain: selectionData.visibility.showOnMain,
        showInRecommendations: checked,
      },
    });
  }

  function openModalProductsList() {
    setCurrentlySelectedProducts(selectionData.products);
    openModal();
  }

  function addProducts() {
    if (actionType === 'editing') {
      dispatch(productToSelectionBind({ selection: selectionData, productUuids })).then(() => {
        dispatch(
          addProductsToSelection({
            selectionUuid: selectionData.uuid,
            products: currentlySelectedProducts,
          }),
        );
        setSelectionData({ ...selectionData, products: currentlySelectedProducts });
        setCurrentlySelectedProducts([]);
        closeModal();
      });
    }
    if (actionType === 'creating') {
      setSelectionData({ ...selectionData, products: currentlySelectedProducts });
      closeModal();
    }
  }

  const { css } = useStyle(ModalSettingSelectionRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: modalFooterRef.current?.clientHeight,
  });
  const { theme } = useTheme();
  const { colorGrey, colorBlack } = theme;

  return (
    <>
      <Modal
        name={ModalNames.SettingsSelection}
        active={active}
        propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
        onClose={onClose}
      >
        <div ref={modalHeaderRef} className={css.header}>
          <Text text={t('modalSettingSelection.selection')} mod="title" />
        </div>
        <div className={css.modalInner} ref={modalInnerRef}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <div className={css.wrapper}>
              <Input
                {...register('selectionTitle', {
                  required: t('modalSettingSelection.requiredField'),
                })}
                controlled={false}
                type="text"
                label={t('modalSettingSelection.name')}
                placeholder={t('modalSettingSelection.enterCollectionName')}
                propsStyles={{ backgroundColor: theme.background }}
                maxLength={200}
                labelTextTransform='uppercase'
                errorMessage={errors.selectionTitle?.message}
              />
            </div>
            <div className={css.wrapper}>
              <ListButton
                onClick={openModalProductsList}
                placeholder={
                  selectionData.products.length > 0
                    ? tPlural('modalSettingSelection.selectedCountProducts', selectionData.products.length)
                    : t('modalSettingSelection.selectProductsFromList')
                }
                titleFontWeight={800}
                title={t('modalSettingSelection.products')}
                titleFontSize={12}
                titleUppercase={true}
              />
              {/* <ListProducts /> */}
            </div>
            <div className={css.wrapper}>
              <div className={css.justifyWrapper}>
                <Text text={t('modalSettingSelection.showOnHomepage')} mod="title" fontSize={12} textTransform='uppercase' />
                <Toggler
                  name="showOnMain"
                  value="showOnMain"
                  checked={selectionData.visibility.showOnMain}
                  // checked={hasButton}
                  onChange={changeShowOnMain}
                />
              </div>
            </div>
            <div className={css.wrapper}>
              <div className={css.justifyWrapper}>
                <Text
                  text={t('modalSettingSelection.showInRecommendations')}
                  mod="title"
                  textTransform='uppercase'
                  fontSize={12}
                />
                <Toggler
                  name="showInRecommendations"
                  value="showInRecommendations"
                  checked={selectionData.visibility.showInRecommendations}
                  onChange={changeShowInRecommendations}
                />
              </div>
            </div>
            {actionType === 'editing' && (
              <div className={css.removeBtnWrapper}>
                <Button
                  text={t('modalSettingSelection.delete')}
                  propsStyles={{ background: colorGrey, color: colorBlack, width: '100%' }}
                  hasGradient={false}
                  onClick={onClickDeleteSelection}
                />
              </div>
            )}

            <div className={css.footer} ref={modalFooterRef}>
              <Button
                disabled={saveNetworkStatus === NetworkStatus.Loading}
                text={
                  actionType === 'creating'
                    ? t('modalSettingSelection.createSelection')
                    : t('modalSettingSelection.save')
                }
                propsStyles={{ width: '100%' }}
                type="submit"
              />
            </div>
          </form>
        </div>
      </Modal>
      {renderModal && (
        <ModalListProducts
          active={activeModal}
          onClose={closeModal}
          onClickProduct={onClickProduct}
          selectedProducts={productUuids}
          onClickAddProducts={addProducts}
        />
      )}
    </>
  );
});
