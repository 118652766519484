import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalSettingSliderKeys {
  modalInner: {};
  header: {};
  info: {};
  footer: {};
  wrapper: {};
  removeBtnWrapper: {};
  form: {};
}

interface IModalSettingSliderProps {
  headerHeight: number;
  footerHeight: number;
}

export type IModalSettingSlider = IRuleFn<IModalSettingSliderKeys, IModalSettingSliderProps>;

const ModalSettingSliderRuleFn: IModalSettingSlider = props => {
  const { colorRgbBlack, mainColor, sidePadding, colorWhite } = props.theme;
  const { headerHeight, footerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      paddingBottom: `${footerHeight}px`,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    header: {
      ...padding(21, sidePadding, 12),
    },
    info: {
      marginTop: 4,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      color: `rgba(${colorRgbBlack}, .4)`,

      '& a': {
        color: mainColor,
      },
    },
    wrapper: {
      ...padding(12, 0),
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12, sidePadding, 30),
    },
    removeBtnWrapper: {
      ...padding(28, 0, 8),
    },
    form: {
      paddingBottom: 20,
    }
  };
};

export const ModalSettingSliderRules = createCachedStyle(ModalSettingSliderRuleFn);
