import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { getSelections } from '../api/selections/getSelections';
import { ISelectionProduct, ISelectionWithProducts } from 'src/types/selection';
import { createSelection } from '../api/selections/createSelection';
import { editSelection } from '../api/selections/editSelection';
import { selectionDelete } from '../api/selections/selectionDelete';

interface IStoriesSlice {
  selections: ISelectionWithProducts[];
  openThisSelection: string;
  networkStatus: NetworkStatus;
  saveNetworkStatus: NetworkStatus;
}

const initialState: IStoriesSlice = {
  selections: [],
  openThisSelection: '',
  networkStatus: NetworkStatus.None,
  saveNetworkStatus: NetworkStatus.None,
};

export const selectionSlice = createSlice({
  name: 'Selections',
  initialState,
  reducers: {
    addProductsToSelection: (
      state,
      action: PayloadAction<{ selectionUuid: string; products: ISelectionProduct[] }>,
    ) => {
      const { selectionUuid, products } = action.payload;
      const selection = state.selections.find(el => el.uuid === selectionUuid);
      selection.products = products;
    },
    setOpenThisSelection: (state, action: PayloadAction<string>) => {
      state.openThisSelection = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getSelections.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getSelections.fulfilled, (state, action) => {
      state.selections = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getSelections.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    
    builder.addCase(createSelection.pending, state => {
      state.saveNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(createSelection.fulfilled, (state, action) => {
      state.selections.push(action.payload);
      state.saveNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(createSelection.rejected, state => {
      state.saveNetworkStatus = NetworkStatus.Failed;
    });

    builder.addCase(editSelection.fulfilled, (state, action) => {
      const editedSelection = action.payload;
      const selection = state.selections.find(el => el.uuid === editedSelection.uuid);
      const selectionIndex = state.selections.indexOf(selection);
      if (selectionIndex === -1) return;
      state.selections.splice(selectionIndex, 1, editedSelection);
    });

    builder.addCase(selectionDelete.fulfilled, (state, action) => {
      state.selections = state.selections.filter(el => el.uuid !== action.payload);
    });
  },
});

export const { addProductsToSelection, setOpenThisSelection } = selectionSlice.actions;

export const selectionsState = (state: RootState) => state[selectionSlice.name];
