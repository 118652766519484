import { InfraProvisioningStatus } from '@teleport/schemas-protobuf';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WizardAccentColor } from 'src/blocks/wizard/WizardAccentColor/WizardAccentColor';
import { WizardEnd } from 'src/blocks/wizard/WizardEnd/WizardEnd';
import { WizardHasBasket } from 'src/blocks/wizard/WizardHasBasket/WizardHasBasket';
import { WizardDeliveryType } from 'src/blocks/wizard/WizardOrderMethod/WizardOrderMethod';
import { WizardProductType } from 'src/blocks/wizard/WizardProductType/WizardProductType';
import { WizardShopName } from 'src/blocks/wizard/WizardShopName/WizardShopName';
import { WizardStart } from 'src/blocks/wizard/WizardStart/WizardStart';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import ModalStoreCreatedResult from 'src/components/ModalStoreCreatedResult/ModalStoreCreatedResult';
import StepsIndicator from 'src/components/StepsIndicator/StepsIndicator';
import StoreCreationScreen from 'src/components/StoreCreationScreen/StoreCreationScreen';
import { Button } from 'src/components/UI/Button/Button';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import useBackButton from 'src/hooks/useBackButton';
import useLoader from 'src/hooks/useLoader';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useModalState } from 'src/hooks/useModalState';
import { RoutePath } from '../../routing/routeConfig';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import { configState } from 'src/redux/slices/configSlice';
import { wizardStateSelector, setStartCreatingStore } from 'src/redux/slices/wizardSlice';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { WIZARD_STEPS, WizardSteps } from 'src/types/wizard';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { getStoreList, multipleStoreState } from '../../redux/slices/multipleStoreSlice';
import { useScrollViewport } from '../../hooks/useScrollViewport';
import { analytics } from '../../services/analytics/analytics';

const ORDER_OF_STEPS = [
  WIZARD_STEPS.NAME_AND_LOGO,
  WIZARD_STEPS.COLOR,
  WIZARD_STEPS.PRODUCT_TYPE,
  WIZARD_STEPS.ORDER_METHOD,
  WIZARD_STEPS.HAS_BASKET,
];

export const WizardPage = memo(function WizardPage() {
  const BackButton = useBackButton();
  const { hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { config } = useAppSelector(configState);
  const {
    stores,
    networkStatus: storeListNetworkStatus
  } = useAppSelector(multipleStoreState);
  const {
    wizard: wizardData,
    complete: wizardIsComplete,
    interruptedStep,
    infraProvisioningStatus,
    networkStatus,
    saveNetworkStatus,
    startCreatingStore,
  } = useAppSelector(wizardStateSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [seenCongratulationsStoreModal] = useLocalStorage('seenCongratulationsStoreModal');
  const [renderResultModal, activeResultModal, openResultModal, closeResultModal] = useModalState();
  const [currentStep, setCurrentStep] = useState<WizardSteps>(() => {
    if (interruptedStep) {
      return interruptedStep;
    }
    if (wizardIsComplete) {
      return 'end';
    }
    return 'start';
  });

  const [shopLogoFile, setShopLogoFile] = useState<null | File>(null);
  const [layoutEl, setLayoutEl] = useState({ current: null });
  const layoutRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLayoutEl({ current: node });
    }
  }, []);

  useScrollViewport({
    distanceFromTopWindow: document.documentElement.clientHeight / 2,
    scrollElementRef: layoutEl,
    dependence1: currentStep
  });

  const intervalID = useRef(null);
  const [storeReadinessStatus, setStoreReadinessStatus] = useState<
    'inProgress' | 'sucess' | 'error' | 'dontStartCreating'
  >('dontStartCreating');

  const pingWizardSaveState = useCallback(() => {
    if (networkStatus === NetworkStatus.Loading) return;
    dispatch(wizardGetState({}));
  }, [dispatch, networkStatus]);

  useEffect(() => {
    if (wizardIsComplete) {
      setCurrentStep('end');
    }
  }, [wizardIsComplete]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState({}));
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, hideLoader, dispatch]);

  // start ping request
  useEffect(() => {
    if (startCreatingStore && wizardIsComplete && !intervalID.current) {
      setStoreReadinessStatus('inProgress');
      intervalID.current = setInterval(() => {
        pingWizardSaveState();
      }, 3000);
    }
  }, [pingWizardSaveState, startCreatingStore, wizardIsComplete]);

  // listener changes infraProvisioningStatus
  useEffect(() => {
    if (!wizardIsComplete) return undefined;
    switch (infraProvisioningStatus) {
      case InfraProvisioningStatus.UNSPECIFIED:
        return undefined;
      case InfraProvisioningStatus.CREATE_IN_PROGRESS:
        dispatch(setStartCreatingStore(true));
        setStoreReadinessStatus('inProgress');
        BackButton?.hide();
        break;
      case InfraProvisioningStatus.CREATE_SUCCESS:
        setCurrentStep('end');
        setStoreReadinessStatus('sucess');
        clearInterval(intervalID.current);
        intervalID.current = null;
        BackButton?.hide();
        if (seenCongratulationsStoreModal) {
          dispatch(setStartCreatingStore(false));
          navigate(RoutePath.Main);
        } else {
          openResultModal();
        }
        break;
      case InfraProvisioningStatus.CREATE_ERROR:
        setCurrentStep('end');
        setStoreReadinessStatus('error');
        dispatch(setStartCreatingStore(false));
        clearInterval(intervalID.current);
        intervalID.current = null;
        BackButton?.hide();
        openResultModal();
        break;
      default:
        setCurrentStep('end');
        setStoreReadinessStatus('sucess');
        dispatch(setStartCreatingStore(false));
        clearInterval(intervalID.current);
        intervalID.current = null;
        BackButton?.hide();
        if (seenCongratulationsStoreModal) {
          navigate(RoutePath.Main);
        } else {
          openResultModal();
        }
    }
  }, [
    dispatch,
    infraProvisioningStatus,
    seenCongratulationsStoreModal,
    BackButton,
    startCreatingStore,
    wizardIsComplete,
    openResultModal,
    navigate,
  ]);

  useEffect(() => {
    if (interruptedStep) {
      setCurrentStep(interruptedStep);
    }
  }, [interruptedStep]);

  const prevStep = useCallback(() => {
    let prevIndex;

    if (currentStep === 'start') {
      return;
    } else if (currentStep === 'end') {
      prevIndex = ORDER_OF_STEPS.length - 1;
    } else {
      const index = ORDER_OF_STEPS.indexOf(currentStep);
      prevIndex = index - 1;
    }

    if (prevIndex < 0) {
      setCurrentStep('start');
      return;
    }

    setCurrentStep(ORDER_OF_STEPS[prevIndex]);
  }, [currentStep]);

  useEffect(() => {
    if(storeListNetworkStatus === NetworkStatus.None && config.toggles.multipleStoreEnabled) {
      dispatch(getStoreList());
    }
  }, [
    dispatch,
    config,
    storeListNetworkStatus
  ]);

  useEffect(() => {

    if(infraProvisioningStatus !== InfraProvisioningStatus.UNSPECIFIED) {
      return undefined;
    }

    function goBack() {
      prevStep();
      if (currentStep === 'start') {
        BackButton?.hide();
      }
    }

    function goBackWhenCreatingAnotherStore() {
      dispatch(wizardGetState({}))
    }

    if (currentStep === 'start' && stores.length >= 1) {
      BackButton.show();
      BackButton.onClick(goBackWhenCreatingAnotherStore);
    }
    else if (currentStep === 'start') {
      BackButton?.hide();
    }
    else {
      BackButton.show();
      BackButton.onClick(goBack);
    }

    return () => {
      BackButton?.offClick(goBack);
      BackButton?.offClick(goBackWhenCreatingAnotherStore);
    };
  }, [
    infraProvisioningStatus,
    dispatch,
    stores,
    BackButton,
    currentStep,
    prevStep
  ]);

  const disabledNextButton = () => {
    switch (currentStep) {
      case WIZARD_STEPS.NAME_AND_LOGO:
        return !Boolean(wizardData.shopName) || !(Boolean(shopLogoFile) || Boolean(wizardData.shopLogoUrl)) || !Boolean(wizardData.shopDescription);
      case WIZARD_STEPS.COLOR:
        return false;
      case WIZARD_STEPS.PRODUCT_TYPE:
        return !Boolean(wizardData.storeFocus.case);
      case WIZARD_STEPS.ORDER_METHOD:
        return !Boolean(wizardData.deliveryType);
      case WIZARD_STEPS.HAS_BASKET:
        return !Boolean(wizardData.hasBasket);
      case 'end':
        return !wizardData.agree || !Boolean(wizardData.supportLink) || !wizardData.language || !wizardData.currency;
      default:
        return false;
    }
  };

  const changeShopLogo = useCallback((logo: File) => {
    setShopLogoFile(logo);
  }, [setShopLogoFile]);

  const nextStep = useCallback(() => {
    if (saveNetworkStatus === NetworkStatus.Loading) return;

    let nextIndex: number;

    if (currentStep === 'end') {
      return;
    } else if (currentStep === 'start') {
      nextIndex = 0;
    } else {
      const index = ORDER_OF_STEPS.indexOf(currentStep);
      nextIndex = index + 1;
    }

    if (nextIndex > ORDER_OF_STEPS.length - 1) {
      setCurrentStep('end');
      return;
    }

    setCurrentStep(ORDER_OF_STEPS[nextIndex]);
  }, [saveNetworkStatus, currentStep]);

  const saveWizardStepData = useCallback(() => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(wizardData, false);
    dispatch(wizardSaveState({ requestData: wizardStateRequest, shopLogoFile }))
      .unwrap()
      .then(() => {
        nextStep();
      });
  }, [shopLogoFile, wizardData, nextStep, dispatch]);

  const completeTheWizard = () => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(wizardData, true);
    dispatch(wizardSaveState({ requestData: wizardStateRequest }))
      .unwrap()
      .then(() => {
        analytics.storeCreating();
        if (currentStep === 'end') {
          dispatch(setStartCreatingStore(true));
        }
        window.scrollTo(0, 0);
        BackButton?.hide();
      })
      .catch(e => console.error(e));
  };

  const content = useMemo(() => {
    if (currentStep === 'start') {
      analytics.wizardStart();

      return <WizardStart/>;
    }

    if (currentStep === WIZARD_STEPS.NAME_AND_LOGO) {
      return (
        <WizardShopName
          setShopLogo={changeShopLogo}
        />
      );
    }

    if (currentStep === WIZARD_STEPS.COLOR) {
      return <WizardAccentColor/>;
    }

    if (currentStep === WIZARD_STEPS.PRODUCT_TYPE) {
      return <WizardProductType/>;
    }

    if (currentStep === WIZARD_STEPS.ORDER_METHOD) {
      return <WizardDeliveryType/>;
    }

    if (currentStep === WIZARD_STEPS.HAS_BASKET) {
      return <WizardHasBasket/>;
    }

    if (currentStep === 'end') {
      return <WizardEnd/>;
    }

    console.warn('unknown wizard step');

    return null;
  }, [currentStep, changeShopLogo]);

  const handleModalClose = () => {
    closeResultModal();
    dispatch(setStartCreatingStore(false));
  }

  return (
    <Layout
      footer={
        <Footer>
          <Button
            text={
              currentStep === 'start'
                ? t('wizardPage.start')
                : currentStep === WIZARD_STEPS.HAS_BASKET
                  ? t('wizardPage.createStore')
                  : currentStep === 'end'
                    ? t('wizardPage.start')
                    : t('wizardPage.continue')
            }
            propsStyles={{
              width: '100%',
            }}
            disabled={saveNetworkStatus === NetworkStatus.Loading ? true : disabledNextButton()}
            onClick={currentStep === 'end' ? completeTheWizard : saveWizardStepData}
          />
        </Footer>
      }
      ref={layoutRef}
    >
      {storeReadinessStatus === 'inProgress' && <StoreCreationScreen />}
      {currentStep !== 'start' && currentStep !== 'end' && (
        <StepsIndicator
          amount={ORDER_OF_STEPS.length}
          activeIndex={ORDER_OF_STEPS.indexOf(currentStep)}
        />
      )}

      {/*<button style={{ position: 'fixed', top: '30px', left: '30px' }} onClick={prevStep}>*/}
      {/*  Назад*/}
      {/*</button>*/}
      {content}
      {renderResultModal && (
        <ModalStoreCreatedResult
          active={activeResultModal}
          onClose={handleModalClose}
          isSuccess={storeReadinessStatus === 'sucess'}
        />
      )}
    </Layout>
  );
});
