import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';
import { padding } from '../../utils/applyCSSProperty ';

export interface IIntegrationTelegramFormRuleKeys {
  form: {};
  textBlock: {};
  warning: {};
  iconInfo: {};
  warningText: {};
  fieldsWrapper: {};
  footer: {};
}

interface IIntegrationTelegramFormProps {
  error: boolean
}

export type IIntegrationTelegramFormRules = IRuleFn<IIntegrationTelegramFormRuleKeys, IIntegrationTelegramFormProps>;

const integrationTelegramFormRuleFn: IIntegrationTelegramFormRules = props => {
  const { colorBlack, colorWhite, colorDarkGrey, colorRed } = props.theme;
  const { error } = props
  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    textBlock: {
      display: 'block'
    },
    warning: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
      columnGap: 8,
      '& svg': {
        fill: error? colorRed : colorDarkGrey,
      }
    },
    iconInfo: {
      fill: colorBlack,
      flexShrink: 0,
    },
    warningText: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '16px',
      color: error? colorRed : colorDarkGrey
    },
    fieldsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
    },
    footer: {
      zIndex: 2,
      backgroundColor: colorWhite,
      ...padding(10, 16, 30),
      width: '100%',
      position: 'fixed',
      left: 0,
      bottom: 0,
    }
  };
};

export const integrationTelegramFormRules = createCachedStyle(integrationTelegramFormRuleFn);
