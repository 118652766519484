import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from '../../utils/network/network.constant';
import { ProductsTranslator } from '../translators/productsTranslator';
import { getProductsByCategory } from '../api/products/getProductsByCategory';
import { createProduct } from '../api/products/createProduct';
import { createAppAsyncThunk } from '../../hooks/redux';
import { upsertProduct } from '../api/products/upsertProduct';
import { deleteProduct } from '../api/products/deleteProduct';
import { IProduct } from 'src/types/product';
import { getProductsBySearch } from '../api/products/getProductsBySearch';
import { Filter, RootCategory } from '@teleport/schemas-protobuf';
import { productToCategoryBind } from './categorySlice';

interface IInitialState {
  products: IProduct[];
  searchedProducts?: IProduct[];
  networkStatus: NetworkStatus;
  selectedCategory?: RootCategory
}

// products services courses events
const initialState: IInitialState = {
  products: [],
  selectedCategory: undefined,
  networkStatus: NetworkStatus.None,
  searchedProducts: []
};

export const getProducts = createAppAsyncThunk('products/getProducts', async (_, thunkAPI) => {
  const result = await thunkAPI.extra.portOwnerApi().ownerCatalogProductList({});
  return ProductsTranslator.fromProductListResponse(result);
});

type PillsFilterCaseType = 'discountEnabledFilter' | 'withoutCategoryFilter' | 'disabledFilter'

export const getProductsByPillsFilter = createAppAsyncThunk(
  'products/getProductsByPillsFilter',
  async (filterCase: PillsFilterCaseType, thunkAPI) => {
    const filter = new Filter({
      filterDefaultState: { case: filterCase, value: {} },
    });

    const result = await thunkAPI.extra
      .portOwnerApi()
      .ownerCatalogProductList({ filters: [filter] });
    return ProductsTranslator.fromProductListResponse(result);
  },
);


export const productsSlice = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    addSelectedCategory: (state, action: PayloadAction<RootCategory | undefined>) => {
      state.selectedCategory = action.payload
    },
    resetSearchedProducts: state => {
      state.networkStatus = NetworkStatus.None
      state.searchedProducts = undefined
    }
  },
  extraReducers: builder => {
    builder.addCase(getProducts.pending, state => {
      state.searchedProducts = undefined;
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(productToCategoryBind.fulfilled, (state, action) => {
      const { category , productUuids } = action.payload;

      state.products = state.products.map(product => {
        if (productUuids.includes(product.id)) {
          return {
            ...product,
            category,
          };
        }
        return product;
      });
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProducts.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(createProduct.fulfilled, (state, action) => {
      state.products.push(action.payload);
    });

    builder.addCase(getProductsByCategory.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getProductsByCategory.fulfilled, (state, action) => {
      state.searchedProducts = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProductsByCategory.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(getProductsBySearch.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getProductsBySearch.fulfilled, (state, action) => {
      state.searchedProducts = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProductsBySearch.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(getProductsByPillsFilter.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getProductsByPillsFilter.fulfilled, (state, action) => {
      state.searchedProducts = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProductsByPillsFilter.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(upsertProduct.fulfilled, (state, { payload }) => {
      const product = state.products.find(el => el.id === payload.id);
      const productIndex = state.products.indexOf(product);
      const upsertProduct = { ...payload, sold: product?.sold ?? 0 };
      if (productIndex !== -1) {
        state.products.splice(productIndex, 1, upsertProduct);
      }
    });

    builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
      state.products = state.products.filter(el => el.id !== payload);
    });
  },
});

export const {
  addSelectedCategory,
  resetSearchedProducts
} = productsSlice.actions;
export const productsState = (state: RootState) => state[productsSlice.name];
