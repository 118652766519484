import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { margin, padding } from 'src/utils/applyCSSProperty ';

export interface IWizardEndRuleKeys {
  inner: {};
  top: {};
  iconWrapper: {};
  guard: {};
  check: {};
  cardWrapper: {};
  info: {};
  documents: {};
  checkbox: {};
  spinner: {};

  // карточка
  card: {};
  cardAdditionalText: {};
}

export type IWizardEndRules = IRuleFn<IWizardEndRuleKeys>;

const wizardEndRuleFn: IWizardEndRules = props => {
  const { appFooterHeight, colorWhite, background, mainColor, colorDarkGrey } = props.theme;

  return {
    inner: {
      paddingTop: 40,
      minHeight: `calc(100vh - ${appFooterHeight}px)`,
      display: 'flex',
      flexDirection: 'column',
    },
    top: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      marginBottom: 40,
    },
    iconWrapper: {
      marginBottom: '30px',
      position: 'relative',
      width: 66,
      height: 83,
    },
    guard: {
      position: 'absolute',
      left: 0,
      top: 0,
    },
    check: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    cardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    },

    info: {
      paddingTop: 16,
      paddingBottom: 12,
      textAlign: 'center',
      color: colorDarkGrey,
    },

    checkbox: {
      width: 20,
      height: 20,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      backgroundColor: mainColor,
      ...padding(4),

      '& svg': {
        fill: colorWhite,
      },

      '& input': {
        opacity: 0,
        position: 'absolute',
        zIndex: -1,
      },
    },

    documents: {
      ...margin(10, 0),
      display: 'flex',
      columnGap: '12px',

      fontSize: 12,
      lineHeight: '16px',
      fontWeight: '600',

      '& button': {
        color: mainColor,
        outline: 'none',
        border: 'none',
        background: 'none',
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: '600',
      },
    },
    spinner: { marginBottom: 16 },

    // Карточка
    card: {
      background: background,
      ...padding(16),
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    cardAdditionalText: {
      color: colorDarkGrey,
    },
  };
};

export const wizardEndRules = createCachedStyle(wizardEndRuleFn);
