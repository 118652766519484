import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';
import { padding } from '../../utils/applyCSSProperty ';

export interface IModalStatisticsKeys {
  modalInner: {};
  header: {};
  period: {};
  periods: {};
  periodActive: {};
  cardWrapper: {};
  noStatisticsSubtitle: {};
  noStatistics: {};
  noStatisticsIcon: {};
  spinnerWrapper: {};
}

export interface IModalStatisticsProps {
  headerHeight: number;
}

export type IModalStatistics = IRuleFn<IModalStatisticsKeys, IModalStatisticsProps>;

const ModalStatisticsRuleFn: IModalStatistics = props => {
  const { sidePadding, mainColor, colorBlack, colorDarkGrey } = props.theme;
  const { headerHeight } = props;

  return {
    header: {
      ...padding(10, sidePadding, 12),
    },
    modalInner: {
      position: 'relative',
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      paddingBottom: 46,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    periods: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20,
    },
    period: {
      flexGrow: 1,
      fontWeight: 800,
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: '18px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 4,
      borderBottom: `1px solid ${colorBlack}`,
      opacity: .3,
    },
    periodActive: {
      opacity: 1,
    },
    cardWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gap: 8,
    },
    noStatistics: {
      paddingTop: 32,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    noStatisticsSubtitle: {
      marginTop: 8,
      color: colorDarkGrey
    },
    noStatisticsIcon: {
      fill: mainColor,
      marginBottom: 26,
    },
    spinnerWrapper: {
      paddingTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  };
};

export const ModalStatisticsRules = createCachedStyle(ModalStatisticsRuleFn);
