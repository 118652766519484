import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IProfilePageKeys {
  header: {};
  grid: {};
  imgLoader: {};
  BottomBtn: {};
  container: {};
  footer: {};
}

interface IProfilePageProps {
  footerHeight: number;
}

export type IProfilePages = IRuleFn<IProfilePageKeys, IProfilePageProps>;

const ProfilePageRuleFn: IProfilePages = props => {
  const { footerHeight } = props;
  const { colorWhite } = props.theme;

  return {
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: footerHeight,
    },
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
    },
    grid: {
      display: 'grid',
      flexDirection: 'column',
      gap: 24,
    },
    imgLoader: {
      marginTop: 16,
      marginBottom: 28,
    },
    BottomBtn: {
      marginTop: 'auto',
      paddingTop: 40,
      marginBottom: '8px',
    },
    footer: {
      ...padding(12, 16, 32),
      backgroundColor: colorWhite,
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
    },
  };
};

export const profilePageRules = createCachedStyle(ProfilePageRuleFn);
