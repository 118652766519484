import { useEffect, useState } from 'react';

type LocalStorageKeys =
  'seenCongratulationsStoreModal'
  | 'noShowModalWarningAsyncChanges'
  | 'previousActiveStoreUuid'
  | 'isCreatingAnotherStore'

export const useLocalStorage = (key: LocalStorageKeys, defaultValue = null) => {
  const [value, setValue] = useState(() => {
    try {
      const saved = localStorage.getItem(key);
      if (saved !== null) {
        return JSON.parse(saved);
      }
      return defaultValue;
    } catch (e) {
      console.error(e);
      return defaultValue;
    }
  });

  useEffect(() => {
    const rawValue = JSON.stringify(value);
    localStorage.setItem(key, rawValue);
  }, [key, value]);

  return [value, setValue];
};
