import { NetworkStatus } from '../../utils/network/network.constant';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { createAppAsyncThunk } from '../../hooks/redux';
import { StatisticPeriod, StatisticUnit } from '@teleport/schemas-protobuf/port/v1/port_statistic_pb';

interface IStatisticSlice {
  statistics:  StatisticUnit[],
  currentStatistics: StatisticUnit;
  networkStatus: NetworkStatus,
}

const initialState: IStatisticSlice = {
  statistics: [],
  currentStatistics: undefined,
  networkStatus: NetworkStatus.None,
};

export const getStatistic = createAppAsyncThunk(
  'Statistic/getStatistic',
  async (_, thunkAPI) => {
    return await thunkAPI.extra.portOwnerApi().statisticGet({})
  },
);

export const statisticsSlice = createSlice({
  name: 'Statistic',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStatistic.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });builder.addCase(getStatistic.fulfilled,(state, action) => {
      state.statistics = action.payload.units;
      state.currentStatistics = action.payload.units.find(el => el.period === StatisticPeriod.NOW);
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getStatistic.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const statisticSelector = (state: RootState) => state[statisticsSlice.name];
