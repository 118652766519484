import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISettingCreateCategoryPageKeys {
  header: {};
  container: {};
  justifyWrapper: {};
  grid: {};
}

export type ISettingCreateCategoryPage = IRuleFn<ISettingCreateCategoryPageKeys>;

const SettingCreateCategoryPageRuleFn: ISettingCreateCategoryPage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(16), 
    },
    container: {
      overflow: 'hidden',
    },
    justifyWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
  };
};

export const SettingCreateCategoryPageRules = createCachedStyle(SettingCreateCategoryPageRuleFn);
