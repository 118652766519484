import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IStoreNoteWorkingRuleKeys {
  app: {};
  wrapper: {};
  img: {};
  text: {};
  title: {};
  subtitle: {};
  button: {};
}

export type IStoreNoteWorkingRules = IRuleFn<IStoreNoteWorkingRuleKeys>;

const storeNoteWorkingRuleFn: IStoreNoteWorkingRules = props => {
  const { appFooterHeight, appMaxWidth, colorWhite, colorBlack } = props.theme;

  return {
    app: {
      maxWidth: appMaxWidth,
      margin: '0 auto',
      minHeight: '100vh',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 580,
      height: `calc(100vh - ${appFooterHeight}px)`,
    },
    img: {
      marginBottom: 27,
    },
    text: {
      textAlign: 'center',
    },
    title: {
      display: 'block',
      marginBottom: 8,
      fontSize: 20,
      lineHeight: '26px',
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    subtitle: {
      fontSize: 16,
      lineHeight: '16px',
      fontWeight: 400,
    },
    footer: {
      ...padding(8, 16),
    },
    button: {
      position: 'relative',
      padding: '22px',
      width: '100%',
      height: 62,
      fontSize: 16,
      fontWeight: 700,
      display: 'flex',
      columnGap: '8px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colorBlack,
      color: colorWhite,
      border: 'none',
      borderRadius: 12,
      borderColor: 'transparent',
      outline: 'none',
      cursor: 'pointer',
      transitionProperty: 'background',
      transitionDuration: '.2s',
      transitionTimingFunction: 'linear',

      '::before': {
        content: '""',
        borderRadius: 12,
        display: 'block',
        position: 'absolute',
        inset: 0,
        background:
          'linear-gradient(90deg, rgba(255,255,255, 0) 30%, rgba(255,255,255,0.346393557422969) 50%, rgba(255,255,255,0.4046393557422969) 100%);',
      },

      ':active': {
        opacity: 0.85,
      },
    },
  };
};

export const storeNoteWorkingRules = createCachedStyle(storeNoteWorkingRuleFn);
