import { FC, memo } from 'react';
import Text from 'src/components/UI/Text/Text';
import { IModifier, getModifierTypeName } from 'src/types/modificator';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModifierCardRules } from './ModifierCard.style';
import { generatePath, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../routing/routeConfig';

interface IProps {
  modifierData: IModifier;
}

export const ModifierCard: FC<IProps> = memo(function ModifierCard(props) {
  const { modifierData } = props;
  const { id, name } = modifierData;
  const { css } = useStyle(ModifierCardRules);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(generatePath(RoutePath.CreateModifier, {id}));
  };

  return (
    <div className={css.card} onClick={handleClick}>
      <Text mod="title" text={name} fontSize={14} lineHeight={16} />
      <Text
        mod={'text'}
        text={getModifierTypeName(modifierData.type)}
        fontWeight={500}
        fontSize={12}
        lineHeight={16}
      />
    </div>
  );
});
