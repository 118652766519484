import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IsearchRuleKeys {
  search: {};
  searchInput: {};
  searchIcon: {};
  searchClose: {};
}

interface ISearchRuleProps {
  open: boolean;
}

export type ISearchRules = IRuleFn<IsearchRuleKeys, ISearchRuleProps>;

const searchRuleFn: ISearchRules = props => {
  const { colorWhite, background, appMaxWidth } = props.theme;
  const {} = props;

  return {
    search: {
      flexGrow: 1,
    },
    searchInput: {
      maxWidth: `calc(${appMaxWidth}px - 32px)`,
      width: '100%',
      height: 38,
      display: 'block',
      position: 'relative',
      zIndex: 21,

      '> input': {
        background: colorWhite,
        ...padding(8, 36),
        border: 'none',
        outline: 'none',
        borderRadius: 8,
        width: '100%',
        height: '100%',
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 500,
      },
    },
    searchIcon: {
      width: 20,
      height: 20,
      pointerEvents: 'none',
      position: 'absolute',
      top: '50%',
      left: '8px',
      transform: 'translateY(-50%)',
    },
    searchClose: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '8px',
      width: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: background,
      borderRadius: '50%',
      outline: 'none',
      border: 'none',
    },
  };
};

export const searchRules = createCachedStyle(searchRuleFn);
