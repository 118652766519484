import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { IPillSoonKeys, PillSoonRules } from './PillSoon.style';
import Text from '../Text/Text';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

interface IProps {
  extend?: Partial<IPillSoonKeys>;
}

export const PillSoon: FC<IProps> = memo(function PillSoon(props) {
  const { extend } = props;
  const { css } = useStyle(PillSoonRules, { extend });

  const {t} = useTranslation();

  return (
    <div className={css.root} >
      <Text
        mod="text"
        text={t('pillSoon.soon')}
        fontSize={10}
        lineHeight={10}
        fontWeight={700}
        extend={css.text}
        textTransform='uppercase'
      />
    </div>
  );
});
