import { createAppAsyncThunk } from '../../../hooks/redux';

export const uploadFiles = createAppAsyncThunk(
  'uploadFiles',
  async (data: {files: File[]}, thunkAPI) => {
    const { files } = data;
    let imageUrls: string[]

    try {
      imageUrls = await thunkAPI.extra.filesUpload(files);
    } catch (e) {
      console.info('Upload files failed: ', e);
    }
    return imageUrls
  }
)
