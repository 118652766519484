import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { useMemo } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import { togglesState } from '../../../redux/slices/configSlice';

export const useSlidesData = () => {
  const { t } = useTranslation();
  const { subscriptionEnabled } = useAppSelector(togglesState);

  return useMemo(() => {
    return [
      {
        text: t('slidesData.howToStart?'),
        img: '/images/template-stories/story1.png',
        href: 'https://teletype.in/@teleportapp.store/mainsettings ',
      },
      {
        text: t('slidesData.howToAddProducts'),
        img: '/images/template-stories/story2.png',
        href: 'https://teletype.in/@teleportapp.store/goods',
      },
      ...subscriptionEnabled
        ? [{
          text: t('slidesData.howToPurchaseSubscription'),
          img: '/images/template-stories/story3.png',
          href: 'https://teletype.in/@teleportapp.store/subscription',
        }]
        : [],
      {
        text: t('slidesData.productModifiers'),
        img: '/images/template-stories/story4.png',
        href: 'https://teletype.in/@teleportapp.store/modifiers',
      },
      {
        text: t('slidesData.usersMailingList'),
        img: '/images/template-stories/story5.png',
        href: 'https://teletype.in/@teleportapp.store/multicast',
      },
      {
        text: t('slidesData.shareYourFeedback'),
        img: '/images/template-stories/story6.png',
        href: 'https://teletype.in/@teleportapp.store/support',
      },
    ];
  }, [t, subscriptionEnabled]);
};
