import { forwardRef, memo, ReactNode, useEffect, useState } from 'react';
import { layoutRules } from './layout.style';
import { useStyle } from '../../utils/theme/useStyle';
import { useModalState } from 'src/hooks/useModalState';
import { useAppSelector } from 'src/hooks/redux';
import { wizardStateSelector } from 'src/redux/slices/wizardSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import ModalError from '../ModalError/ModalError';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

interface IError {
  title?: string;
  text?: string;
  triggers: NetworkStatus[];
}

interface ILayout {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  mainFlexColumn?: boolean;
  padding?: string;
  position?: 'fixed';
  top?: number;
  left?: number;
  zIndex?: number;
  errorData?: IError;
}
export const Layout = memo(
  forwardRef<HTMLDivElement, ILayout>(function Layout(props, ref) {
    const { t } = useTranslation();
    const [contentError, setContentError] = useState({
      title: t('layout.error'),
      text: ''
    });
    const {
      children,
      header,
      footer,
      padding,
      top,
      left,
      position,
      zIndex,
      errorData = {
        triggers: [],
      }
    } = props;

    // // Telegram scroll fix !!!
    useEffect(() => {
      const overflow = 1;
      function setupDocument() {
        document.body.style.overflowY = 'hidden';
        document.body.style.marginTop = `${overflow}px`;
        document.body.style.height = window.innerHeight + overflow + 'px';
        document.body.style.paddingBottom = `${overflow}px`;
        // смещаем окно так, чтобы body оказался в верхнем левом углу
        window.scrollTo(0, overflow);
        // setTimeout(() => {
        //   document.body.style.marginTop = '';
        //   document.body.style.paddingBottom = '';
        //   window.scrollTo(0, 0);
        // }, 100)
      }

      const onScroll = () => {
        window.scrollTo(0, overflow);
      }

      setupDocument();
      window.addEventListener('focusout', onScroll);
      return () => {
        window.removeEventListener('focusout', onScroll);
      }
    }, []);

    const { css } = useStyle(layoutRules, {
      mainFlexColumn: props.mainFlexColumn,
      padding,
      position,
      top,
      left,
      zIndex,
    });
    const { saveStateError, saveNetworkStatus } = useAppSelector(wizardStateSelector);
    const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();

    useEffect(() => {
      if (saveNetworkStatus === NetworkStatus.Failed || errorData.triggers.find(el => el === NetworkStatus.Failed)) {
        openErrorModal();
      }
    }, [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...errorData.triggers,
      errorData,
      saveStateError,
      openErrorModal,
      closeErrorModal,
      saveNetworkStatus
    ]);

    useEffect(() => {
      if(saveNetworkStatus === NetworkStatus.Failed) {
        setContentError({ title: t('failedToUpdateTheStore'), text: `${t('layout.error')}: ${saveStateError}`});
      }
      else if(errorData.triggers.find(el => el === NetworkStatus.Failed)) {
        setContentError({
          title: errorData.title || t('layout.error'),
          text: errorData.text || '',
        });
      }
    }, [t, saveNetworkStatus, saveStateError, errorData]);

    return (
      <div className={`${css.root} root-layout`} ref={ref}>
        {header}
        <main className={css.main}>{children}</main>
        {footer}
        {renderErrorModal && (
          <ModalError
            onClose={closeErrorModal}
            title={contentError.title}
            text={contentError.text}
            active={activeErrorModal}
          />
        )}
      </div>
    );
  }),
);
