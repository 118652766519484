import { memo, useRef } from 'react';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import { useStyle } from 'src/utils/theme/useStyle';
import { modalListRules } from './ModalList.style';
import { Option } from '../../../../Option/Option';
import Text from 'src/components/UI/Text/Text';
import { IListWithCardOption } from '../../ListWithCards';

interface IProps {
  active: boolean;
  title?: string;
  options: IListWithCardOption[];
  onChange: (value: string) => void;
  selectedValue: string;
  onClose: () => void;
}

const ModalList = function ModalList(props: IProps) {
  const { active, selectedValue, options, title, onChange, onClose } = props;
  const modalHeaderRef = useRef<HTMLDivElement>(null);

  const { css } = useStyle(modalListRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });

  return (
    <Modal
      active={active}
      name={ModalNames.List}
      onClose={onClose}
      propsStyles={{
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
      }}
    >
      <div ref={modalHeaderRef} className={css.header}>
        {title && <Text text={title} mod="title" />}
      </div>
      <div className={css.modalInner}>
        {options.map(el => (
          <Option
            key={el.value}
            value={el.value}
            title={el.title}
            subtitle={el.subtitle}
            checked={selectedValue === el.value}
            type="radio"
            symbol={el.symbol}
            disabled={el.disabled}
            onChange={onChange}
            soon={el.soon}
          />
        ))}
      </div>
    </Modal>
  );
};

export default memo(ModalList);
