import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICategory, ISubCategory } from 'src/types/categories';
import { createProduct } from '../api/products/createProduct';
import { NetworkStatus } from 'src/utils/network/network.constant';
// import { IModifier } from 'src/types/modificator';
import { getProductDetail } from '../api/products/getProductDetaitl';
import { ISelection } from 'src/types/selection';
import {
  OwnerCatalogProductDetailResponse,
  OwnerCatalogProductFull,
} from '@teleport/schemas-protobuf';
import { ProductsTranslator } from '../translators/productsTranslator';
import { upsertProduct } from '../api/products/upsertProduct';
import { RootState } from '../store';

interface IInitialState {
  category: ICategory | null;
  subcategory: ISubCategory | null;
  selections: ISelection[];
  installingModifiers: boolean;
  installingCategories: boolean;
  initialProductData: OwnerCatalogProductFull | null;
  detailsNetworkStatus: NetworkStatus;
  createNetworkStatus: NetworkStatus;
  upsertNetworkStatus: NetworkStatus;
  saveStateError?: string;
}

// products services courses events
const initialState: IInitialState = {
  category: null,
  subcategory: null,
  selections: [],
  installingCategories: false,
  installingModifiers: false,
  detailsNetworkStatus: NetworkStatus.None,
  createNetworkStatus: NetworkStatus.None,
  upsertNetworkStatus: NetworkStatus.None,
  initialProductData: null,
  saveStateError: '',
};

export const productSlice = createSlice({
  name: 'Product',
  initialState,
  reducers: {
    setInstallingModifiers: (state, action: PayloadAction<boolean>) => {
      state.installingModifiers = action.payload;
    },
    setInstallingCategories: (state, action: PayloadAction<boolean>) => {
      state.installingCategories = action.payload;
    },
    setProductCategory: (state, action: PayloadAction<ICategory>) => {
      state.category = action.payload;
    },
    setProductSubcategory: (state, action: PayloadAction<ISubCategory>) => {
      state.subcategory = action.payload;
    },
    setSelectionsForProduct: (state, action: PayloadAction<ISelection[]>) => {
      state.selections = action.payload;
    },
    resetNetworkStatus: (
      state,
      {
        payload,
      }: PayloadAction<'detailsNetworkStatus' | 'createNetworkStatus' | 'upsertNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
    changeProductImageUrls: (state, action: PayloadAction<string[]>) => {
      if (state.initialProductData?.imageUrls) {
        state.initialProductData.imageUrls = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(createProduct.pending, state => {
      state.createNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(createProduct.fulfilled, state => {
      state.category = null;
      state.subcategory = null;
      // state.showInCatalog = true;
      state.selections = [];
      state.createNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(createProduct.rejected, state => {
      state.createNetworkStatus = NetworkStatus.Failed;
    });

    builder.addCase(upsertProduct.pending, state => {
      state.upsertNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(upsertProduct.fulfilled, state => {
      state.upsertNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(upsertProduct.rejected, state => {
      state.upsertNetworkStatus = NetworkStatus.Failed;
    });

    builder.addCase(getProductDetail.pending, state => {
      state.saveStateError = undefined;
      state.detailsNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(
      getProductDetail.fulfilled,
      (state, { payload }: PayloadAction<OwnerCatalogProductDetailResponse>) => {
        state.initialProductData = payload.product;

        const product = ProductsTranslator.fromGetProductDetail(payload);
        state.selections = product.selections;
        state.category = product.category;
        state.subcategory = product.subCategory;
        state.detailsNetworkStatus = NetworkStatus.Done;
      },
    );
    builder.addCase(getProductDetail.rejected, (state, action) => {
      state.saveStateError = action.error.message;
      state.detailsNetworkStatus = NetworkStatus.Failed;
    });
  },
});

export const productState = (state: RootState) => state[productSlice.name];

export const {
  // setProductId,
  setInstallingModifiers,
  setInstallingCategories,
  setProductCategory,
  setProductSubcategory,
  setSelectionsForProduct,
  resetNetworkStatus,
  changeProductImageUrls
} = productSlice.actions;
