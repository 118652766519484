import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { ProductsPageRules } from './ProductsPage.style';
import { ProductsCard } from 'src/components/ProductsCard/ProductsCard';
import Search from 'src/components/Search/Search';
import Pill from 'src/components/UI/Pill/Pill';
import PillCategories from 'src/components/UI/PillCategories/PillCategories';
import { NetworkStatus } from '../../utils/network/network.constant';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  addSelectedCategory,
  getProducts,
  getProductsByPillsFilter,
  productsState,
  resetSearchedProducts,
} from '../../redux/slices/productsSlice';
import { ICategoryState } from 'src/components/ModalProductCategories/ModalProductCategories';
import useLoader from 'src/hooks/useLoader';
import useBackButton from 'src/hooks/useBackButton';
import { RoutePath } from '../../routing/routeConfig';
import { generatePath, useNavigate } from 'react-router-dom';
import { ProductsTranslator } from 'src/redux/translators/productsTranslator';
import { getProductsByCategory } from 'src/redux/api/products/getProductsByCategory';
import { getProductsBySearch } from 'src/redux/api/products/getProductsBySearch';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { useModalState } from 'src/hooks/useModalState';
import ModalError from 'src/components/ModalError/ModalError';

export const ProductsPage: FC = memo(function ProductsPage(props) {
  const {} = props;
  const { css } = useStyle(ProductsPageRules);
  const { hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { products, networkStatus, selectedCategory, searchedProducts } =
    useAppSelector(productsState);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();
  const [pillWrapper, setPillWrapper] = useState(null)
  const pillWrapperRef = useCallback(node => {
    if (node !== null) {
      setPillWrapper(node);
    }
  }, []);

  useEffect(() => {
    if(!pillWrapper) return undefined;
    pillWrapper.addEventListener('wheel', function(e) {
      const isStart = e.deltaY > 0 && pillWrapper.scrollLeft >= pillWrapper.scrollWidth - pillWrapper.offsetWidth;
      const isEnd = e.deltaY < 0 && pillWrapper.scrollLeft === 0

      if(isStart || isEnd) {
        return
      }

      if(e.deltaY > 0) {
        e.preventDefault();
        pillWrapper.scrollLeft += Math.abs(e.deltaY);
      }
      else if (e.deltaY < 0) {
        e.preventDefault();
        pillWrapper.scrollLeft -= Math.abs(e.deltaY);
      }
    })
  }, [pillWrapper]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.Failed) {
      openErrorModal();
    }
  }, [networkStatus, openErrorModal]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);

    function goBack() {
      navigate(RoutePath.Main);
    }

    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getProducts());
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [dispatch, hideLoader, networkStatus]);

  // все | категория | без категории | скидка
  const [filter, setFilter] = useState<
    'all' | 'category' | 'without-category' | 'discount' | 'hidden'
  >('all');

  const handleChangePill = event => {
    const value = event.target.value;
    setFilter(value);

    if (value !== 'category') {
      dispatch(addSelectedCategory(undefined));
    }

    if (value === 'all') {
      dispatch(getProducts());
    }
    if (value === 'discount') {
      dispatch(getProductsByPillsFilter('discountEnabledFilter'));
    }
    if (value === 'hidden') {
      dispatch(getProductsByPillsFilter('disabledFilter'));
    }
    if (value === 'without-category') {
      dispatch(getProductsByPillsFilter('withoutCategoryFilter'));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetSearchedProducts());
      setFilter('all');
    };
  }, [dispatch]);

  const toggleHiddingProduct = event => {
    const productId = event.currentTarget.dataset.productId;
    const product = products.find(el => el?.id === productId);

    // todo fixme
    if (!product) return;
    // product?.hidden = !product.hidden;
    // setProducts([...products]);
  };

  const clickSelectCategoriesButton = (categories: ICategoryState[]) => {
    const requestData = ProductsTranslator.toProductListByCategory(categories);
    dispatch(getProductsByCategory(requestData));
  };

  const sendSearchRequest = (value: string) => {
    if (value) {
      dispatch(getProductsBySearch(value));
    } else {
      setFilter('all');
      dispatch(getProducts());
    }
  };

  const renderProducts = useMemo(() => {
    if (searchedProducts) {
      return (
        <>
          {searchedProducts?.length > 0 ? (
            searchedProducts.map(product => (
              <ProductsCard
                product={product}
                key={product.id}
                isActive={false}
                hidingFunctionality={true}
                onClickHideBtn={toggleHiddingProduct}
                href={generatePath(RoutePath.EditProduct, {id: product.id})}
              />
            ))
          ) : (
            <Container>
              <p className={css.noResults}>{t('productsPage.noResultsFoundForYourQuery')} :(</p>
            </Container>
          )}
        </>
      );
    }
    return (
      <>
        {products.map(product => (
          <ProductsCard
            product={product}
            key={product.id}
            isActive={false}
            hidingFunctionality={true}
            onClickHideBtn={toggleHiddingProduct}
            href={generatePath(RoutePath.EditProduct, {id: product.id})}
          />
        ))}
      </>
    );
    // eslint-disable-next-line
  }, [products, searchedProducts, css.noResults]);

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('productsPage.products')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
          {products.length > 0 && (
            <Text
              mod="text"
              fontSize={20}
              lineHeight={24}
              text={products.length.toString()}
              extend={css.amountProducts}
            />
          )}
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('productsPage.createProduct')}
            propsStyles={{ width: '100%' }}
            href={generatePath(RoutePath.CreateProduct)}
          />
        </Footer>
      }
    >
      {products.length > 0 ? (
        <>
          <div className={css.filteringMethods}>
            <Container>
              <Search sendSearchRequestFn={sendSearchRequest} />
            </Container>
            <div className={css.pillsWrapper} ref={pillWrapperRef} >
              <Pill
                name="products-filter"
                type="radio"
                value="all"
                text={t('productsPage.all')}
                amount={products.length > 0 ? products.length : 0}
                checked={filter === 'all'}
                onChange={handleChangePill}
              />
              <PillCategories
                clickSelectCategories={clickSelectCategoriesButton}
                name="products-filter"
                type="radio"
                text={selectedCategory ? `${selectedCategory.title}` : t('productsPage.category')}
                amount={
                  searchedProducts && filter === 'category'
                    ? searchedProducts.filter(product => product.category).length
                    : products.filter(product => product.category).length
                }
                checked={filter === 'category'}
                onChange={handleChangePill}
              />
              <Pill
                name="products-filter"
                type="radio"
                text={t('productsPage.noCategory')}
                amount={products.filter(product => !product.category).length}
                value="without-category"
                checked={filter === 'without-category'}
                onChange={handleChangePill}
              />
              <Pill
                name="products-filter"
                type="radio"
                text={t('productsPage.hidden')}
                value="hidden"
                checked={filter === 'hidden'}
                amount={products.filter(product => !product.enabled).length}
                onChange={handleChangePill}
              />
              <Pill
                name="products-filter"
                type="radio"
                text={t('productsPage.withDiscount')}
                value="discount"
                checked={filter === 'discount'}
                amount={products.filter(product => product.price.discountEnabled).length}
                onChange={handleChangePill}
              />
            </div>
          </div>
          <div className={css.productsWrapper}>{renderProducts}</div>
        </>
      ) : (
        <Container>
          <NoSettings text={t('productsPage.noProductsYet')} icon="products-large" />
        </Container>
      )}
      {renderErrorModal && (
        <ModalError onClose={closeErrorModal} title={'Ошибка'} active={activeErrorModal} />
      )}
    </Layout>
  );
});
