import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IOptionCardRuleKeys {
  cardWrapper: {};
  card: {};
  labelWrp: {};
  disabledMessage: {};
}

interface IOptionCardRuleProps {
  soon?: boolean;
  disabled?: boolean;
  hasDisabledMessage?: boolean;
  bg?: string
  checked: boolean
}

export type IOptionCardRules = IRuleFn<IOptionCardRuleKeys, IOptionCardRuleProps>;

const optionCardFn: IOptionCardRules = props => {
  const { colorWhite, colorRed, mainColor, colorRgbRed, colorRgbWhite } = props.theme;
  const { disabled, hasDisabledMessage, soon, bg, checked } = props;

  return {
    cardWrapper: {
      pointerEvents: disabled || soon ? 'none' : 'auto',
      backgroundColor: hasDisabledMessage ? `rgba(${colorRgbRed}, 0.1)` : 'transparent',
      borderRadius: 12,
    },

    disabledMessage: {
      ...padding(4, 16),
      color: colorRed,
      opacity: 1,
    },

    card: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },
    labelWrp: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      justifyContent: 'space-between',
      borderRadius: 12,
      backgroundColor: bg ?? colorWhite,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: checked? mainColor : 'transparent',
      position: 'relative',
      ...padding(15),

      ':before': {
        content: '""',
        display: disabled ? 'block' : 'none',
        position: 'absolute',
        inset: -1,
        borderRadius: 12,
        backgroundColor: soon? 'transparent' : `rgba(${colorRgbWhite}, 0.5)`,
      },

      '& input': {
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
      },
    }
  };
};

export const optionCardRules = createCachedStyle(optionCardFn);
