import {
  ChangeEvent,
  ReactNode,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { TextareaRules } from './Textarea.style';
import Text from '../Text/Text';

interface CommonProps {
  name: string;
  placeholder?: string;
  label?: string;
  maxLength?: number;
  message?: ReactNode;
  letterCounter?: boolean;
  propsStyles?: IStyles;
  errorMessage?: string;
  labelTextTransform?: 'uppercase' | 'none' | 'capitalize';
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
}

type ConditionalProps =
  | {
      controlled: true;
      value: string;
      onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    }
  | {
      controlled: false;
      value?: never;
      onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    };

type IProps = CommonProps & ConditionalProps;

interface IStyles {
  backgroundColor?: string;
  minHeight?: number;
}

export const Textarea = memo(
  forwardRef<HTMLTextAreaElement, IProps>(function Textarea(props, ref) {
    const {
      name,
      maxLength,
      controlled,
      placeholder,
      value = '',
      label,
      letterCounter,
      message,
      errorMessage,
      propsStyles,
      labelTextTransform,
      onChange,
      onFocus,
      onBlur,
    } = props;
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [currentValue, setCurrentValue] = useState(value);
    const [valid, setValid] = useState({ valid: true, message: '' });
    const { css } = useStyle(TextareaRules, {
      backgroundColor: propsStyles?.backgroundColor,
      isInvaild: !valid.valid,
      minHeight: propsStyles?.minHeight,
    });

    useImperativeHandle(ref, () => textareaRef.current, []);

    useEffect(() => {
      if (errorMessage && errorMessage.length) {
        setValid({ valid: false, message: errorMessage });
      }
    }, [errorMessage]);

    useEffect(() => {
      const textarea = textareaRef.current;

      setCurrentValue(textarea.value);

      if (textarea) {
        textarea.style.height = `${textarea?.scrollHeight}px`;
        textarea.addEventListener('input', changeAreaHeight);
      }
      return () => {
        textarea?.removeEventListener('input', changeAreaHeight);
      };
    }, [textareaRef]);

    function changeAreaHeight(this: HTMLTextAreaElement) {
      this.style.height = '';
      this.style.height = `${this.scrollHeight}px`;
    }

    function onChangeValue(event: ChangeEvent<HTMLTextAreaElement>) {
      setCurrentValue(event.target.value);
      setValid({ valid: true, message: '' });
      onChange && onChange(event);
    }

    function handleFocus(event: React.FocusEvent<HTMLTextAreaElement, Element>) {
      const input = event.currentTarget;
      // fix safari open keyboard scroll
      // input.style.opacity = '0';
      // setTimeout(() => (input.style.opacity = '1'));
      // fix carret position
      setTimeout(() => {
        input.selectionStart = input.selectionEnd = 10000;
      }, 0);
      onFocus && onFocus(event);
    }

    return (
      <div>
        <div className={css.top}>
          {label && (
            <label>
              <Text text={label} mod="title" fontSize={12} textTransform={labelTextTransform ? labelTextTransform : 'none'} />
            </label>
          )}
          {letterCounter && (
            <Text mod="text" text={`${currentValue.length}/${maxLength}`} fontSize={12} />
          )}
        </div>
        <div className={`${css.wrapper} textareaWrapper`}>
          {controlled ? (
            <textarea
              ref={textareaRef}
              name={name}
              maxLength={maxLength}
              className={css.textarea}
              placeholder={placeholder}
              value={value}
              onChange={onChangeValue}
              onFocus={handleFocus}
              onBlur={onBlur}
            />
          ) : (
            <textarea
              ref={textareaRef}
              name={name}
              maxLength={maxLength}
              className={css.textarea}
              placeholder={placeholder}
              onChange={onChangeValue}
              onFocus={handleFocus}
              onBlur={onBlur}
            />
          )}
        </div>
        {(valid.message || message) && (
          <p className={css.message}>{valid.message ? valid.message : message}</p>
        )}
      </div>
    );
  }),
);
