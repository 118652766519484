import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { NoSettingsRules } from './NoSettings.style';
import Text from '../UI/Text/Text';
import { Icon, IconList } from '../UI/Icon/Icon';

interface IProps {
  title?: string;
  text?: string;
  icon: IconList;
}

export const NoSettings: FC<IProps> = memo(function NoSettings(props) {
  const { title, text, icon } = props;

  const [footerHeight, setFooterHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const footerHeight = document.querySelector<HTMLElement>('.footer')?.offsetHeight || 0;
    const headerHeight = document.querySelector<HTMLElement>('.header')?.offsetHeight || 0;

    setFooterHeight(footerHeight);
    setHeaderHeight(headerHeight);
  }, []);

  const { css } = useStyle(NoSettingsRules, { footerHeight, headerHeight });

  return (
    <div className={css.wrapper}>
      <Icon name={icon} properties={{ className: css.icon }} />
      {title && <Text text={title} mod={'title'} extend={css.title} fontWeight={700} />}
      {text && <Text text={text} mod={'text'} extend={css.text} fontWeight={600} fontSize={14} lineHeight={16} />}
    </div>
  );
});
