import { FC, memo, useCallback, } from 'react';
import { AccentColor } from 'src/components/AccentColor/AccentColor';
import { Step } from 'src/components/Step/Step';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { setAccentColor, wizardStateSelector } from '../../../redux/slices/wizardSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { configState } from '../../../redux/slices/configSlice';

export const WizardAccentColor: FC = memo(function WizardAccentColor() {
  const { wizard: { accentColor: wizardAccentColor } } = useAppSelector(wizardStateSelector);
  const { config: { accentColor: configAccentColor } } = useAppSelector(configState);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const accentColor = wizardAccentColor || configAccentColor;

  const changeAccentColor = useCallback((color: string) => {
    dispatch(setAccentColor(color));
  }, [dispatch]);

  return (
    <Step
      stepNumber={2}
      title={t('wizardStepAccent.chooseAccentColor')}
      subtitle={t('wizardStepAccent.willBeUsedForButtonsAndOtherElements')}
    >
      <AccentColor
        accentColor={accentColor}
        setAccentColor={changeAccentColor}
      />
    </Step>
  );
});
