import React, { FC, memo, useEffect } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { useTheme } from '../../utils/theme/useTheme';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Textarea } from '../UI/Textarea/Textarea';
import { Button } from '../UI/Button/Button';
import { integrationTelegramFormRules } from './IntegrationTelegramForm.style';
import { Icon } from '../UI/Icon/Icon';
import { Input } from '../UI/Input/Input';
import { linkMaxLength } from '../../utils/constants';
import { useValidateUrl } from '../../utils/hooks/useValidateUrl';
import { correctUrlOnPaste, trimAndReplaceFunction } from '../../utils/validateUrl';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { channelIntegrationUpsertRequest, IIntegrationData, integrationState, resetIntegrationError } from 'src/redux/slices/integrationSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { configState } from 'src/redux/slices/configSlice';

interface IProps {
  onSubmitCallback?: () => void
}

export const IntegrationTelegramForm: FC<IProps> = memo(function IntegrationTelegramForm({onSubmitCallback}) {
  const { theme } = useTheme();
  const validateUrl = useValidateUrl();
  const dispatch = useAppDispatch()
  const { saveNetworkStatus, integration, errorText} = useAppSelector(integrationState);
  const { css } = useStyle(integrationTelegramFormRules, {error: errorText && errorText.includes('bot_is_not_admin')});
  const { config } = useAppSelector(configState);
  const { hubBotUsername } = config;

  const createOnChangeUrlFunction = (onChangeCallBack: (...event: any[]) => void) => {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      const { inputType } = event.nativeEvent as InputEvent;
      if (inputType === 'insertFromPaste') {
        const newVal = correctUrlOnPaste(event)
        onChangeCallBack(newVal);
      } else {
        onChangeCallBack(trimAndReplaceFunction(event.target.value));
      }
    }
  }

  const { t, tPlural } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      channelLink: integration.channelLink ?? '',
      messageText: '',
      buttonText: '',
    },
  });

  const onSubmit: SubmitHandler<IIntegrationData> = data => {
    dispatch(channelIntegrationUpsertRequest(data))
  };

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      dispatch(resetIntegrationError())
      onSubmitCallback()
    }
  }, [saveNetworkStatus, onSubmitCallback, dispatch])


  return (
    <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={css.warning}>
        <Icon name={'info'} properties={{className: css.iconInfo}} />
        <p className={css.warningText}>{t('integrationTelegramForm.toPublishAmessageYouNeedToAddOurBotAsAnAdministratorOfTheChannel', `@${hubBotUsername}`)}</p>
      </div>
      <div className={css.fieldsWrapper}>
        <Controller
          control={control}
          name='channelLink'
          rules={{
            required: t('integrationTelegramForm.requiredField'),
            validate: {
              checkUrl: validateUrl,
            },
            maxLength: {
              value: linkMaxLength,
              message: tPlural('integrationTelegramForm.linkShouldBeLessThanCharacters', linkMaxLength)
            },
          }}
          render={({ field: { name, onChange, value, ref } }) => (
            <Input
              ref={ref}
              name={name}
              type="text"
              onChange={createOnChangeUrlFunction(onChange)}
              value={value}
              controlled={true}
              label={t('integrationTelegramForm.link')}
              placeholder={t('integrationTelegramForm.insertTheLinkToTheTelegramChannel')}
              propsStyles={{ backgroundColor: theme.background }}
              maxLength={1000}
              labelTextTransform='uppercase'
              errorMessage={errors.channelLink?.message}
            />
          )}
        />
        <Textarea
          {...register('messageText', { required: t('integrationTelegramForm.requiredField') })}
          label={t('integrationTelegramForm.text')}
          labelTextTransform='uppercase'
          controlled={false}
          placeholder={t('integrationTelegramForm.forExampleOrderingHasBecomeEvenEasierWeNowHaveOurOwnAppAnTelegram')}
          propsStyles={{ backgroundColor: theme.background }}
          errorMessage={errors.messageText?.message}
          maxLength={512}
        />
        <Input
          {...register('buttonText', {
            required: t('integrationTelegramForm.requiredField'),
          })}
          controlled={false}
          type="text"
          label={t('integrationTelegramForm.button')}
          placeholder={t('integrationTelegramForm.forУxampleGoTo')}
          propsStyles={{ backgroundColor: theme.background }}
          maxLength={60}
          letterCounter={true}
          labelTextTransform='uppercase'
          errorMessage={errors.buttonText?.message}
        />
      </div>
      <div className={css.footer}>
        <Button
          text={t('maintenanceEnabledForm.save')}
          propsStyles={{ width: '100%' }}
          type="submit"
          disabled={saveNetworkStatus === NetworkStatus.Loading}
        />
      </div>
    </form>
  );
});
