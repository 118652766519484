import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IProductImagesLoaderKeys {
  loaderWrapper: {};
  labelWrp: {};
  labelText: {};
  loader: {};
  imagesWrapper: {};
  message: {};
}

export type IProductImagesLoader = IRuleFn<IProductImagesLoaderKeys>;

const ProductImagesLoaderRuleFn: IProductImagesLoader = props => {
  const { mainColor, colorGrey, colorRgbBlack } = props.theme;
  const {} = props;

  return {
    loaderWrapper: {
      overflow: 'hidden',
    },
    labelWrp: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',

      '& input': {
        position: 'absolute',
        zIndex: -1,
        opacity: 0,
      },
    },
    loader: {
      width: 112,
      height: 112,
      borderRadius: 8,
      backgroundColor: colorGrey,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    labelText: {
      userSelect: 'none',
      color: mainColor,
    },
    message: {
      marginTop: 2,
      color: `rgba(${colorRgbBlack}, .5)`,
    },
    imagesWrapper: {
      overflow: 'hidden',
      display: 'inline-flex',
      flexWrap: 'wrap',
      gap: '8px 3.5px',
      marginBottom: 8,
    },
  };
};

export const ProductImagesLoaderRules = createCachedStyle(ProductImagesLoaderRuleFn);
