import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as IconSearch } from 'src/assets/icons/search.svg';
import { useStyle } from 'src/utils/theme/useStyle';
import { searchRules } from './search.style';
import { Icon } from '../UI/Icon/Icon';
import { debounce } from 'src/utils/debounce';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  onChange?: (value: string) => void;
  sendSearchRequestFn: (value: string) => void;
}

const Search: FC<IProps> = function Search(props) {
  const { onChange, sendSearchRequestFn } = props;
  const [open, setOpen] = useState(false);
  const { css } = useStyle(searchRules, { open });
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  // eslint-disable-next-line
  const sendSearchRequestDebounce = useCallback(debounce(sendSearchRequestFn, 1500), [
    sendSearchRequestFn,
  ]);

  const handleChange = event => {
    const value = event.target.value;
    setSearchValue(value);
    if (onChange) {
      onChange(value);
    }
    if (value.length > 2 || value.length === 0) {
      sendSearchRequestDebounce(value);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [open]);

  useEffect(() => {
    const input = inputRef.current;
    function listenInputKey(event) {
      if (event.key === 'Enter') {
        input.blur();
      }
    }
    input.addEventListener('keyup', listenInputKey);
    return () => {
      input.removeEventListener('keyup', listenInputKey);
    };
  }, []);

  const openSearch = () => {
    setOpen(true);
  };

  const closeSearch = () => {
    setOpen(false);
    setSearchValue('');
    sendSearchRequestFn('');
  };

  return (
    <div className={css.searchInput}>
      <IconSearch className={css.searchIcon} />
      <input
        placeholder={t('search.search')}
        onClick={openSearch}
        value={searchValue}
        onChange={handleChange}
        ref={inputRef}
      />
      {searchValue && (
        <button className={css.searchClose} onClick={closeSearch}>
          <Icon name="cross" />
        </button>
      )}
    </div>
  );
};

export default memo(Search);
