import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Container from 'src/components/Container/Container';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import Text from 'src/components/UI/Text/Text';
import { categoryPageRules } from './categoryPage.style';
import { useStyle } from 'src/utils/theme/useStyle';
import useLoader from 'src/hooks/useLoader';
import { useNavigate, useParams } from 'react-router-dom';
import {
  categoryState,
  getProductsByExactCategory,
  getRootCategoryList,
  productToCategoryBind,
} from 'src/redux/slices/categorySlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useTheme } from 'src/utils/theme/useTheme';
import { useModalState } from 'src/hooks/useModalState';
import { CategoryCard } from 'src/components/CategoryCard/CategoryCard';
import { ProductsList } from 'src/components/ProductsList/ProductsList';
import ModalListProducts from 'src/components/ModalListProducts/ModalListProducts';
import { ISelectionProduct } from 'src/types/selection';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { IProduct } from 'src/types/product';
import ModalError from 'src/components/ModalError/ModalError';
import { Category } from '@teleport/schemas-protobuf';
import useBackButton from 'src/hooks/useBackButton';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

// СТРАНИЦА КОНКРЕТНОЙ КАТЕГОРИИ
export const CategoryPage: FC = memo(function CategoryPage() {
  const { id } = useParams();
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();
  const { categories, networkStatus, products } = useAppSelector(categoryState);

  const dispatch = useAppDispatch();

  const { css } = useStyle(categoryPageRules);
  const { theme } = useTheme();
  const { colorGrey } = theme;
  const BackButton = useBackButton();
  const navigate = useNavigate();

  const { hideLoader } = useLoader();

  const { t } = useTranslation()

  const category = useMemo(() => {
    return categories.find(obj => obj.uuid === id);
  }, [categories, id]);

  useEffect(() => {
    function goBack() {
      navigate(-1);
    }
    BackButton.onClickCustom(goBack);

    return () => {
      BackButton.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getRootCategoryList());
    }
    hideLoader();
  }, [hideLoader, category, networkStatus, dispatch]);

  const fetchProducts = useCallback(() => {
    const category = new Category({
      type: {
        value: {
          uuid: id,
          title: 'test',
        },
        case: 'rootCategory',
      },
    });
    dispatch(getProductsByExactCategory(category)).then(() => {
      return false;
      // console.log('res', res);
    });
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      fetchProducts();
    }
  }, [id, fetchProducts]);

  const [currentlySelectedProducts, setCurrentlySelectedProducts] = useState<ISelectionProduct[]>(
    [],
  );

  useEffect(() => {
    setCurrentlySelectedProducts(
      products.map(el => {
        return {
          uuid: el.id,
          previewImageUrls: el.imageUrls,
        };
      }),
    );
  }, [products]);

  const productUuids = currentlySelectedProducts.map(el => el.uuid);

  const onClickProduct = event => {
    const product: IProduct = JSON.parse(event.currentTarget.dataset.product);
    const selectionProduct: ISelectionProduct = {
      uuid: product.id,
      previewImageUrls: product.imageUrls,
    };

    if (currentlySelectedProducts.some(el => el.uuid === selectionProduct.uuid)) {
      setCurrentlySelectedProducts(
        [...currentlySelectedProducts].filter(el => el.uuid !== selectionProduct.uuid),
      );
    } else {
      setCurrentlySelectedProducts([...currentlySelectedProducts, selectionProduct]);
    }
  };

  const addProducts = () => {
    dispatch(
      productToCategoryBind({
        category: category,
        productUuids: productUuids,
      }),
    ).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        closeModal();
        fetchProducts();
      } else {
        closeModal();
        openErrorModal();
      }
    });
  };

  const renderCategoryInnerContent = useMemo(() => {
    if (category?.subcategories) {
      if (category.subcategories.length > 0) {
        return (
          <div className={css.grid}>
            {category.subcategories.map(subcategory => {
              return (
                <CategoryCard
                  isSubCategory={true}
                  key={subcategory.uuid}
                  categoryData={subcategory}
                />
              );
            })}
          </div>
        );
      }

      return (
        <NoSettings
          text={'Создайте подкатегории для категории'}
          title={'Вы пока не создали подкатегорий'}
          icon={'categories-large'}
        />
      );
    }

    if (products?.length > 0) {
      return (
        <div className={css.grid}>
          {products && (
            <ProductsList
              notationText={t('categoryPage.everyThirdCardWillBeLargeInTheStoreCatalog')}
              data={products}
            />
          )}
        </div>
      );
    }

    return (
      <NoSettings
        text={t('categoryPage.youHaventAddedAnyProductsToTheCategoryYet')}
        title={t('categoryPage.addProductsToTheCategory')}
        icon={'products-large'}
      />
    );
    // eslint-disable-next-line
  }, [category, products]);

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={category?.title ?? 'Категория'}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
          {category && (
            <Button
              icon="edit"
              href={`/categories/${id}/edit`}
              propsStyles={{ padding: '8px', background: colorGrey }}
            />
          )}
        </header>
      }
      footer={
        <Footer>
          {category?.subcategories ? (
            <Button
              href={`/create-subcategory/${id}`}
              text="Создать подкатегорию"
              propsStyles={{ width: '100%' }}
            />
          ) : (
            <Button onClick={openModal} text={t('categoryPage.addProducts')} propsStyles={{ width: '100%' }} />
          )}
        </Footer>
      }
    >
      <Container>{renderCategoryInnerContent}</Container>
      {renderModal && (
        <ModalListProducts
          active={activeModal}
          onClose={closeModal}
          onClickProduct={onClickProduct}
          selectedProducts={productUuids}
          onClickAddProducts={addProducts}
        />
      )}
      {renderErrorModal && (
        <ModalError onClose={closeErrorModal} title={'Ошибка'} active={activeErrorModal} />
      )}
    </Layout>
  );
});
