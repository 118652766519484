import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISlideCardKeys {
  card: {};
  image: {};
  iconLink: {};
  iconBars: {};
  linkWrap: {};
  link: {};
  buttonDrag: {};
  fallbackSvg: {};
}

interface ISlideCardProps {
  transform: any;
  transition: any;
}

export type ISlideCard = IRuleFn<ISlideCardKeys, ISlideCardProps>;

const SlideCardRuleFn: ISlideCard = props => {
  const { colorRgbBlack, colorDarkGrey, colorWhite } = props.theme;
  const { transform, transition } = props;

  return {
    card: {
      touchAction: 'manipulation',
      // touchAction: 'none',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      ...padding(12),
      display: 'grid',
      columnGap: 16,
      gridTemplateColumns: 'auto 1fr auto',
      alignItems: 'center',
      backgroundColor: colorWhite,
      borderRadius: 12,
      transform,
      transition,
    },
    image: {
      borderRadius: 8,
      objectFit: 'cover',
      width: 100,
      height: 100,
    },
    text: { marginBottom: 12 },
    linkWrap: {
      flexShrink: 2,
      display: 'flex',
      columnGap: 4,
      color: `rgba(${colorRgbBlack}, .5)`,
    },
    iconLink: {
      width: 16,
      height: 16,
      fill: `rgba(${colorRgbBlack}, .5)`,
      flexShrink: 0,
    },
    link: {
      wordWrap: 'anywhere',
    },
    iconBars: {
      flexShrink: 0,
      width: 20,
      height: 20,
      fill: colorDarkGrey,
    },
    buttonDrag: {
      border: 'none',
      background: 'none',
      // width: 30,
      // height: 30,
      outline: 'none',
      touchAction: 'none',
    },
    fallbackSvg: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
    },
  };
};

export const SlideCardRules = createCachedStyle(SlideCardRuleFn);
