import { memo, useEffect, useState } from 'react';
import { MainPageHeader } from 'src/blocks/MainPageHeader/MainPageHeader';
import { MainPageSection } from 'src/blocks/MainPageSection/MainPageSection';
import { ShopDisableStateTxt, ShopState } from 'src/blocks/ShopState/ShopState';
import { StoreNumbers } from 'src/blocks/StoreNumbers/StoreNumbers';
import { Stories } from 'src/blocks/Stories/Stories';
import Container from 'src/components/Container/Container';
import { Layout } from 'src/components/Layout/Layout';
import { SettingButton } from 'src/components/UI/SettingButton/SettingButton';
import useLoader from 'src/hooks/useLoader';
import { ConnectSupport } from 'src/blocks/ConnectSupport/ConnectSupport';
import { RoutePath } from '../../routing/routeConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { categoryState, getRootCategoryList } from '../../redux/slices/categorySlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { getRootTextBlockList, textBlockState } from 'src/redux/slices/textBlockSlice';
import { wizardStateSelector } from 'src/redux/slices/wizardSlice';
import PersonalDevelopment from 'src/components/PersonalDevelopment/PersonalDevelopment';
import { storiesState } from 'src/redux/slices/storiesSlice';
import { getStories } from 'src/redux/api/stories/getStories';
import { selectionsState } from 'src/redux/slices/selectionsSlice';
import { getSelections } from 'src/redux/api/selections/getSelections';
import { SliderState } from 'src/redux/slices/sliderSlice';
import { getSliderList } from 'src/redux/api/slider/getSliderList';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { InfraProvisioningStatus } from '@teleport/schemas-protobuf';
import { useModalState } from 'src/hooks/useModalState';
import ModalStoreCreatedResult from 'src/components/ModalStoreCreatedResult/ModalStoreCreatedResult';
import useBackButton from 'src/hooks/useBackButton';
import { productsState } from 'src/redux/slices/productsSlice';
import { recomendationState } from 'src/redux/slices/recomendationSlice';
import { getRecomendations } from 'src/redux/api/recomendation/getRecomendations';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { subscriptionState, subsctiptionStatusGet } from 'src/redux/slices/subscriptionSlice';
import { togglesState } from '../../redux/slices/configSlice';
import { useTheme } from '../../utils/theme/useTheme';
import { ModalStatistics } from '../../components/ModalStatistics/ModalStatistics';

export const MainPage = memo(function MainPage() {
  const { networkStatus: subscriptionNetworkStatus, subscriptionActive, finishedAt } = useAppSelector(subscriptionState);
  const { categories, networkStatus: categoryNetworkStatus } = useAppSelector(categoryState);
  const { textBlocks, networkStatus: textBlockNetworkStatus } = useAppSelector(textBlockState);
  const { stories, networkStatus: storiesNetworkStatus } = useAppSelector(storiesState);
  const { selections, networkStatus: selectionsNetworkStatus } = useAppSelector(selectionsState);
  const { slider, networkStatus: sliderNetworkStatus } = useAppSelector(SliderState);
  const { products, networkStatus: productsNetworkStatus } = useAppSelector(productsState);
  const { recomendations, networkStatus: recomendationNetworkStatus } =
    useAppSelector(recomendationState);
  const { subscriptionEnabled, newsletterEnabled } = useAppSelector(togglesState);
  const BackButton = useBackButton();
  const dispatch = useAppDispatch();
  const {
    complete: wizardIsComplete,
    infraProvisioningStatus,
    wizard,
    networkStatus,
  } = useAppSelector(wizardStateSelector);
  const { showLoader, hideLoader } = useLoader();

  const [shopDisabledText, setShopDisabledText] = useState<string | undefined>('');

  const [seenCongratulationsStoreModal] = useLocalStorage('seenCongratulationsStoreModal');
  const [
    renderCongratulationsModal,
    activeCongratulationsModal,
    openCongratulationsModal,
    closeCongratulationsModal,
  ] = useModalState();

  const [
    renderStatisticsModal,
    activeStatisticsModal,
    openStatisticsModal,
    closeStatisticsModal,
  ] = useModalState();

  const onClickStatistic = () => {
    openStatisticsModal();
  }

  const { t } = useTranslation();
  const { theme } = useTheme();

  useEffect(() => {
    BackButton.hide();
  }, [BackButton]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done && productsNetworkStatus === NetworkStatus.Done) {
      hideLoader();
    }
    if (!seenCongratulationsStoreModal) {
      openCongratulationsModal();
    }
  }, [
    networkStatus,
    seenCongratulationsStoreModal,
    openCongratulationsModal,
    showLoader,
    hideLoader,
    productsNetworkStatus,
  ]);

  // fixme remove this requests
  useEffect(() => {
    if (subscriptionEnabled && subscriptionNetworkStatus === NetworkStatus.None) {
      dispatch(subsctiptionStatusGet());
    }
    if (categoryNetworkStatus === NetworkStatus.None) {
      dispatch(getRootCategoryList());
    }
    if (textBlockNetworkStatus === NetworkStatus.None) {
      dispatch(getRootTextBlockList());
    }
    if (storiesNetworkStatus === NetworkStatus.None) {
      dispatch(getStories());
    }
    if (selectionsNetworkStatus === NetworkStatus.None) {
      dispatch(getSelections());
    }
    if (sliderNetworkStatus === NetworkStatus.None) {
      dispatch(getSliderList());
    }
    if (recomendationNetworkStatus === NetworkStatus.None) {
      dispatch(getRecomendations());
    }
    if (subscriptionActive !== undefined) {
      hideLoader();
    }
  }, [
    dispatch,
    infraProvisioningStatus,
    wizardIsComplete,
    categoryNetworkStatus,
    textBlockNetworkStatus,
    storiesNetworkStatus,
    selectionsNetworkStatus,
    sliderNetworkStatus,
    recomendationNetworkStatus,
    subscriptionNetworkStatus,
    subscriptionActive,
    subscriptionEnabled,
    hideLoader,
  ]);

  useEffect(() => {
    if (subscriptionEnabled && !subscriptionActive) {
      setShopDisabledText(ShopDisableStateTxt.SubscriptionNotActive);
    } else if (wizard.maintenanceEnabled) {
      setShopDisabledText(ShopDisableStateTxt.MaintenanceEnabled);
    } else if (!products?.length) {
      setShopDisabledText(ShopDisableStateTxt.EmptyShop);
    } else {
      setShopDisabledText(undefined);
    }
  }, [wizard.maintenanceEnabled, subscriptionActive, products, subscriptionEnabled]);

  const isCategoryListLoading =
    categoryNetworkStatus === NetworkStatus.None || categoryNetworkStatus === NetworkStatus.Loading;
  const isTextBlockListLoading =
    textBlockNetworkStatus === NetworkStatus.None ||
    textBlockNetworkStatus === NetworkStatus.Loading;
  const isStoriesLoading =
    storiesNetworkStatus === NetworkStatus.None || storiesNetworkStatus === NetworkStatus.Loading;
  const isSelectionsLoading =
    selectionsNetworkStatus === NetworkStatus.None ||
    selectionsNetworkStatus === NetworkStatus.Loading;
  const isSliderLoading =
    sliderNetworkStatus === NetworkStatus.None || sliderNetworkStatus === NetworkStatus.Loading;
  const isRecommendationLoading =
    recomendationNetworkStatus === NetworkStatus.None ||
    recomendationNetworkStatus === NetworkStatus.Loading;
  const isShopStateLoaded = networkStatus === NetworkStatus.Done
    && (!subscriptionEnabled || subscriptionNetworkStatus === NetworkStatus.Done || subscriptionNetworkStatus === NetworkStatus.Failed)
    && productsNetworkStatus === NetworkStatus.Done;

  if (!wizardIsComplete || infraProvisioningStatus === InfraProvisioningStatus.CREATE_IN_PROGRESS) {
    return null;
  }

  return (
    <Layout padding="0 0 42px">
      <Container>
        <MainPageHeader/>
      </Container>
      <Stories propsStyles={{ paddingTop: 10 }}/>
      <Container>
        {isShopStateLoaded && (
          <ShopState
            subscriptionInactive={subscriptionEnabled && (subscriptionNetworkStatus === NetworkStatus.Failed || !subscriptionActive || !finishedAt)}
            shopDisabledText={shopDisabledText}
            isMaintenanceEnabled={wizard.maintenanceEnabled}
            isShopHasNoProducts={!products.length}
          />
        )}
        <StoreNumbers onClick={onClickStatistic} />
        <MainPageSection>
          <SettingButton
            title={t('mainPage.mainPageBlocksOrder')}
            href={RoutePath.BlocksOrder}
            icon={'block-order'}
            backgroundColorForIcon={theme.colorPink}
          />
          <SettingButton
            title={t('mainPage.stories')}
            href={RoutePath.Stories}
            icon={'stories'}
            count={stories.length}
            isCountLoading={isStoriesLoading}
            backgroundColorForIcon={theme.colorBlue}
          />
          <SettingButton
            title={t('mainPage.textBlocks')}
            href={RoutePath.TextBlocks}
            icon={'text-block'}
            count={textBlocks.length}
            isCountLoading={isTextBlockListLoading}
            backgroundColorForIcon={theme.colorGreen}
          />
          <SettingButton
            title={t('mainPage.slider')}
            href={RoutePath.Slider}
            icon={'slider'}
            count={slider?.items.length}
            isCountLoading={isSliderLoading}
            backgroundColorForIcon={theme.colorOrange}
          />
          <SettingButton
            title={t('mainPage.selections')}
            href={RoutePath.Selections}
            icon={'selections'}
            count={selections.length}
            isCountLoading={isSelectionsLoading}
            backgroundColorForIcon={theme.colorBlueLight}
          />
        </MainPageSection>
        <MainPageSection>
          <SettingButton
            title={t('mainPage.productCategories')}
            href={RoutePath.Categories}
            icon={'categories'}
            count={categories.length}
            isCountLoading={isCategoryListLoading}
            backgroundColorForIcon={theme.colorGreenDark}
          />
          <SettingButton
            title={t('mainPage.modifiers')}
            href={RoutePath.Modifiers}
            icon={'modificators'}
            soon={true}
            count={1}
            backgroundColorForIcon={theme.colorRedLight}
          />
          <SettingButton
            title={t('mainPage.recommendations')}
            icon={'recommendations'}
            href={RoutePath.Recommendation}
            isCountLoading={isRecommendationLoading}
            count={recomendations.length}
            backgroundColorForIcon={theme.colorPurple}
          />
        </MainPageSection>
        <MainPageSection>
          <SettingButton
            title={t('mainPage.mailingList')}
            icon={'newsletter'}
            soon={!newsletterEnabled}
            href={RoutePath.Newsletters}
            backgroundColorForIcon={theme.colorOrangeLight}
          />
          <SettingButton
            title={t('mainPage.promoCodes')}
            icon={'promocodes'}
            soon={true}
            backgroundColorForIcon={theme.colorPurpleLight}
          />
        </MainPageSection>
        <MainPageSection>
          <SettingButton
            title={t('mainPage.storeSettings')}
            href={RoutePath.StoreSetup}
            icon={'general'}
            backgroundColorForIcon={theme.colorGreenLight}
          />
          <SettingButton
            title={t('mainPage.orderAndDelivery')}
            href={RoutePath.OrderingAndDelivery}
            icon={'delivery'}
            backgroundColorForIcon={theme.colorGray}
          />
          <SettingButton
            title={t('mainPage.payment')}
            href={''}
            icon={'payment'}
            soon={true}
            backgroundColorForIcon={theme.colorYellow}
          />
        </MainPageSection>
        <ConnectSupport/>
        <PersonalDevelopment propsStyles={{ marginBottom: 12, marginTop: 12 }}/>
      </Container>
      {renderCongratulationsModal && (
        <ModalStoreCreatedResult
          active={activeCongratulationsModal}
          onClose={closeCongratulationsModal}
          isSuccess={true}
        />
      )}
      {renderStatisticsModal && <ModalStatistics active={activeStatisticsModal} onClose={closeStatisticsModal} />}
    </Layout>
  );
});
