import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IPersonalDevelopmentRuleKeys {
  wrapper: {};
  title: {};
  text: {};
  button: {};
  image: {};
}

interface IPersonalDevelopmentRuleProps {
  marginBottom?: number;
  marginTop?: number;
}

export type IPersonalDevelopmentRules = IRuleFn<
  IPersonalDevelopmentRuleKeys,
  IPersonalDevelopmentRuleProps
>;

const PersonalDevelopmentRuleFn: IPersonalDevelopmentRules = props => {
  const { colorWhite } = props.theme;
  const { marginBottom, marginTop } = props;

  return {
    wrapper: {
      position: 'relative',
      background: 'radial-gradient(circle, rgba(1,152,233,1) 50%, rgba(1,132,255,1) 100%)',
      ...padding(60, 20, 20),
      borderRadius: 16,
      marginTop,
      marginBottom,
    },
    image: {
      width: '100%',
      marginBottom: 20,
    },

    button: {
      // zIndex: 1,
    },

    title: {
      textAlign: 'center',
      color: colorWhite,
      display: 'block',
      marginBottom: 8,
    },
    text: {
      textAlign: 'center',
      color: colorWhite,
      display: 'block',
      marginBottom: 20,
    },
  };
};

export const PersonalDevelopmentRules = createCachedStyle(PersonalDevelopmentRuleFn);
