import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { SortableRecomendationCardRules } from './SortableRecomendationCard.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IRecomendation } from 'src/redux/slices/recomendationSlice';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routing/routeConfig';
import { useAppDispatch } from '../../../hooks/redux';
import { setOpenThisSelection } from '../../../redux/slices/selectionsSlice';

interface IProps {
  data: IRecomendation;
}

export const SortableRecomendationCard: FC<IProps> = memo(
  function SortableRecomendationCard(props) {
    const { data } = props;
    const { title, id } = data;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onClickCard = () => {
      dispatch(setOpenThisSelection(id));
      navigate(RoutePath.Selections);
    }

    const { css } = useStyle(SortableRecomendationCardRules, {
      transform: CSS.Transform.toString(transform),
      transition,
    });

    return (
      <div
        className={css.card}
        id={`${id}`}
        onClick={onClickCard}
        {...attributes} ref={setNodeRef}>
        <div className={css.info}>
          <Text mod="title" text={title} fontSize={14} lineHeight={16} fontWeight={600} />
        </div>
        <button {...listeners} className={css.buttonDrag}>
          <Icon name="bars" properties={{ className: css.iconBars }} />
        </button>
      </div>
    );
  },
);
