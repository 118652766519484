import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routeConfig } from './routing/routeConfig';
import { useStyle } from './utils/theme/useStyle';
import { appRules } from './App.style';
import { wizardGetState } from './redux/api/wizard/wizardGetState';
import { setUserName } from './redux/slices/userSlice';
import { setAccentColor, wizardStateSelector } from './redux/slices/wizardSlice';
import { StoreNotWorking } from './components/StoreNotWorking/StoreNotWorking';
import { FC, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { NetworkStatus } from './utils/network/network.constant';
import { configState } from './redux/slices/configSlice';

// swiper
import { register } from 'swiper/element/bundle';
import 'swiper/css';
register();

const router = createBrowserRouter(routeConfig);

export const App: FC = memo(function App() {
  const { css } = useStyle(appRules);
  const { networkStatus, wizard } = useAppSelector(wizardStateSelector);
  const { config } = useAppSelector(configState);
  const dispatch = useAppDispatch();

  // route parsing goes here

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState({}));

      const username = window.Telegram?.WebApp?.initDataUnsafe?.user?.username;
      if (username) {
        dispatch(setUserName(username));
      }
    }

    if (networkStatus === NetworkStatus.Done && !wizard.accentColor) {
      dispatch(setAccentColor(config.accentColor));
    }
  }, [networkStatus, dispatch, config, wizard.accentColor]);

  if (networkStatus === NetworkStatus.Failed) {
    return (
      <StoreNotWorking reasonText={'Error receiving wizard data'}/>
    );
  }

  return (
    <div className={css.app}>
      <RouterProvider router={router}/>
    </div>
  );
});
