import { useContext } from 'react';
import { AnalyticsContext } from '../analytics.constant';

export const useAnalytics = () => {
  const analytics = useContext(AnalyticsContext);

  if (AnalyticsContext === null) {
    console.error('Analytics context is not defined');
  }

  return analytics;
};
