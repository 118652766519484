import { FC, memo, useCallback, useEffect } from 'react';
import { useStyle } from '../../../utils/theme/useStyle';
import Container from 'src/components/Container/Container';
import { wizardEndRules } from './wizardEnd.style';
import { Icon } from 'src/components/UI/Icon/Icon';
import Text from 'src/components/UI/Text/Text';
import { useTheme } from 'src/utils/theme/useTheme';
import Wrapper from 'src/components/Wrapper/Wrapper';
import { ListWithCard } from 'src/components/UI/Lists/ListWithCards/ListWithCards';
import { useTelegram } from 'src/utils/telegramProvider';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { setAgree, setCurrency, setLanguage, setSupportLink, wizardStateSelector } from 'src/redux/slices/wizardSlice';
import { ClientSupportForm } from 'src/components/ClientSupportForm/ClientSupportForm';
import { userState } from 'src/redux/slices/userSlice';
import { freeTrialPeriod } from 'src/utils/constants';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { useCurrencyData } from '../../../utils/hooks/useCurrencyData';
import { useLanguageData } from '../../../utils/hooks/useLanguageData';
import { Trans } from '../../../utils/i18n/components/trans';
import { togglesState } from '../../../redux/slices/configSlice';

export const WizardEnd: FC = memo(function WizardEnd() {
  const {
    wizard: {
      agree,
      language,
      currency,
      supportLink,
    },
  } = useAppSelector(wizardStateSelector);
  const { userName } = useAppSelector(userState);
  const { subscriptionEnabled } = useAppSelector(togglesState);
  const { css } = useStyle(wizardEndRules);
  const { theme } = useTheme();
  const { mainColor, background, colorWhite } = theme;
  const { webApp } = useTelegram();
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const dispatch = useAppDispatch();

  const { t, tPlural } = useTranslation();

  const currencyOptions = useCurrencyData();
  const languageOptions = useLanguageData();

  useEffect(() => {
    setAgree(true);
    if (supportLink) {
      dispatch(setSupportLink(supportLink));
    } else if (userName) {
      dispatch(setSupportLink(`@${userName}`));
    }
    // eslint-disable-next-line
  }, []);

  const openLicenseLink = () => {
    webApp.openLink('https://teletype.in/@teleportapp.store/license', { try_instant_view: true });
  };

  const openPrivacyPolicyLink = () => {
    webApp.openLink('https://teletype.in/@teleportapp.store/privacy_policy ', { try_instant_view: true });
  };

  const onChangeLanguage = useCallback((value: string) => {
    dispatch(setLanguage(Number(value)));
  }, [dispatch]);
  const onChangeCurrency = useCallback((value: string) => {
    dispatch(setCurrency(Number(value)));
  }, [dispatch]);

  const onChangeAgree = useCallback(() => {
    dispatch(setAgree(!agree));
  }, [dispatch, agree]);

  return (
    <>
      <div className={css.inner}>
        <Container>
          <div className={css.top}>
            <div className={css.iconWrapper}>
              <Icon name="guard" properties={{ fill: mainColor, className: css.guard }}/>
              <Icon name="check" properties={{ fill: mainColor, className: css.check }}/>
            </div>
            <Text text={t('wizardStepEnd.almostThere')} mod="title" fontSize={24} lineHeight={24}/>
          </div>
        </Container>
        <Wrapper
          propsStyles={{
            backgroundColor: colorWhite,
            borderRadius: '10px 10px 0 0',
            padding: '16px 16px 0 16px',
            flexGrow: '1'
          }}
        >
          <div className={css.cardWrapper}>
            {subscriptionEnabled && (
              <div className={css.card}>
                <Text
                  mod="text"
                  text={t('wizardStepEnd.freeTrialPeriod')}
                  fontSize={16}
                  fontWeight={700}
                  lineHeight={18}
                />
                <Text
                  text={tPlural('wizardStepEnd.countDays', freeTrialPeriod)}
                  mod="text"
                  fontSize={16}
                  fontWeight={700}
                  lineHeight={18}
                  extend={css.cardAdditionalText}
                />
              </div>
            )}
            <ListWithCard
              options={currencyOptions}
              placeholder={t('wizardStepEnd.chooseCurrency')}
              title={t('wizardStepEnd.currency')}
              modalTitle={t('wizardStepEnd.currency')}
              initialValue={String(currency)}
              onChange={onChangeCurrency}
              propsStyles={{ backgroundColor: background }}
            />
            <ListWithCard
              options={languageOptions}
              placeholder={t('wizardStepEnd.chooseLanguage')}
              title={t('wizardStepEnd.language')}
              modalTitle={t('wizardStepEnd.language')}
              initialValue={String(language)}
              onChange={onChangeLanguage}
              propsStyles={{ backgroundColor: background }}
            />
            <ListButton
              id={'client-support'}
              title={t('wizardStepEnd.customerSupport')}
              placeholder={`${supportLink ? supportLink : userName ? `@${userName}` : ''}`}
              bg={theme.background}
              onClick={openModal}
              padding="16px"
            />
          </div>
          <Text
            mod="text"
            text={t('wizardStepEnd.changeSettingsAndFillStoreWithDataThoughtThisAppAtAnyTime')}
            fontSize={14}
            fontWeight={600}
            lineHeight={16}
            extend={css.info}
          />
          <div className={css.documents}>
            <label className={css.checkbox}>
              <input
                type="checkbox"
                name="agree"
                value={t('wizardStepEnd.iAgree')}
                checked={agree}
                onChange={onChangeAgree}
              />
              {agree && <Icon name="check"/>}
            </label>
            <p>
              <Trans
                text={t('wizardStepEnd.iAcceptTheLicenseAgreementAndPrivacyPolicy', '<0>', '</0>', '<1>', '</1>')}
                components={[
                  <button key={1} onClick={openLicenseLink}/>,
                  <button key={2} onClick={openPrivacyPolicyLink}/>,
                ]}
              />
            </p>
          </div>
        </Wrapper>
      </div>
      {renderModal && (
        <ModalUniversal
          active={activeModal}
          title={t('wizardStepEnd.customerSupport')}
          onClose={closeModal}
        >
          <ClientSupportForm sendRequest={false} closeModal={closeModal}/>
        </ModalUniversal>
      )}
    </>
  );
});
