import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Button } from '../UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import { ModalConfirmRules } from './ModalConfirm.style';
import ModalCenter, { ModalCenterNames } from '../Modal/ModalCenter';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import Text from '../UI/Text/Text';

interface IProps {
  onClose: () => void;
  active: boolean;
  confirmAction: () => void;
  title: string;
  subtitle?: string
  confirmTxt?: string;
  cancelTxt?: string;
}

const ModalConfirm = function ModalConfirm(props: IProps) {
  const { active, confirmAction, confirmTxt, cancelTxt, onClose, title, subtitle } = props;
  const { css } = useStyle(ModalConfirmRules);
  const { theme } = useTheme();

  const { t } = useTranslation()

  return (
    <ModalCenter active={active} name={ModalCenterNames.ConfirmDelete} onClose={onClose}>
      <div>
        <div className={css.textWrapper}>
          <h4 className={css.title}>{title}</h4>
          {subtitle && <Text
            text={subtitle}
            mod='text'
            fontSize={13}
            lineHeight={18}
            fontWeight={400}
          />}
        </div>
        <div className={css.btnsWrp}>
          <Button
            text={cancelTxt ?? t('modalConfirm.no')}
            propsStyles={{
              background: 'transparent',
              color: '#037EE5',
            }}
            onClick={() => onClose()}
            hasGradient={false}
          />
          <span className={css.separator} />
          <Button
            text={confirmTxt ?? t('modalConfirm.yes')}
            propsStyles={{
              background: 'transparent',
              color: theme.colorRed,
            }}
            onClick={() => confirmAction()}
            hasGradient={false}
          />
        </div>
      </div>
    </ModalCenter>
  );
};

export default memo(ModalConfirm);
