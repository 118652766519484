import ReactDOM from 'react-dom/client';
import { RendererProvider, ThemeProvider } from 'react-fela';
import { App } from './App';
import { renderer } from './styles/fela';
import { Provider } from 'react-redux';
import { ThemeName } from './utils/theme/theme.interface';
import { getTheme } from './utils/theme/getTheme';
import { TelegramProvider } from './utils/telegramProvider';
import './index.css';
import { IConfig } from './utils/config/config.interface';
import { getStore } from './redux/store';
import { StoreNotWorking } from './components/StoreNotWorking/StoreNotWorking';
import { isConfigValid } from './utils/config/isConfigValid';
import { Theme } from './components/Theme/Theme';
import { setConfig, setTranslation } from './redux/slices/configSlice';
import { ITranslation, Language } from './utils/i18n/i18n.interface';
import { AnalyticsProvider } from './services/analytics/analyticsProvider';
import { Analytics } from './services/analytics/analytics';

export function render(config: IConfig, translation: ITranslation, telegramLanguage: Language) {
  const root = ReactDOM.createRoot(document.getElementById('root'));

  // send initial analytics
  const analytics = Analytics.instance;
  analytics.appStart();

  const content = () => {
    if (!isConfigValid(config) || !translation) {
      const reason = config === null ? 'Config is not available' : 'Invalid config';

      return (
        <ThemeProvider theme={getTheme(ThemeName.Light)}>
          <StoreNotWorking
            reasonText={reason}
          />
        </ThemeProvider>
      );
    }

    const store = getStore(config);
    store.dispatch(setConfig(config));
    store.dispatch(setTranslation({ translation, language: telegramLanguage }));

    return (
      <Provider store={store}>
        <Theme>
          <App/>
        </Theme>
      </Provider>
    );
  };

  root.render(
    <TelegramProvider>
      <RendererProvider renderer={renderer}>
        <AnalyticsProvider analytics={analytics}>
          {content()}
        </AnalyticsProvider>
      </RendererProvider>
    </TelegramProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
