import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModifiersForProductKeys {
  header: {};
  container: {};
  modifierWrapper: {};
  modifier: {};
}

export type IModifiersForProduct = IRuleFn<IModifiersForProductKeys>;

const ModifiersForProductRuleFn: IModifiersForProduct = props => {
  const { colorWhite } = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(16), 
    },
    container: {
      overflow: 'hidden',
    },
    modifierWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    },
    modifier: {
      backgroundColor: colorWhite,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
      ...padding(12),
      borderRadius: 12,
    },
  };
};

export const ModifiersForProductRules = createCachedStyle(ModifiersForProductRuleFn);
