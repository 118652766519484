import { memo, useEffect, useRef, useState } from 'react';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalListProductsRules } from './ModalListProducts.style';
import { Button } from 'src/components/UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import Search from 'src/components/Search/Search';
import Pill from 'src/components/UI/Pill/Pill';
import PillCategories from 'src/components/UI/PillCategories/PillCategories';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getProducts, productsState } from 'src/redux/slices/productsSlice';
import useLoader from 'src/hooks/useLoader';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { ProductsCard } from 'src/components/ProductsCard/ProductsCard';
import { Spinner } from 'src/components/UI/Spinner/Spinner';
import { ICategoryState } from '../ModalProductCategories/ModalProductCategories';
import { getProductsByCategory } from 'src/redux/api/products/getProductsByCategory';
import { ProductsTranslator } from 'src/redux/translators/productsTranslator';
import { IProduct } from 'src/types/product';
import { getProductsBySearch } from 'src/redux/api/products/getProductsBySearch';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  active: boolean;
  deep?: number;
  selectedProducts: string[];
  onClose: () => void;
  onClickProduct: (event) => void;
  onClickAddProducts: () => void;
}

const ModalListProducts = function ModalListProducts(props: IProps) {
  const { active, deep, selectedProducts, onClickAddProducts, onClickProduct, onClose } = props;
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const { t, tPlural } = useTranslation();

  const { css } = useStyle(ModalListProductsRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });
  const { theme } = useTheme();
  const { hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { products, networkStatus } = useAppSelector(productsState);
  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getProducts());
    }
    hideLoader();
  }, [hideLoader, dispatch, networkStatus]);

  // все | категория | без категории | скидка
  const [filter, setFilter] = useState<'all' | 'category' | 'without-category' | 'hidden'>('all');
  const handleChangePill = event => {
    const value = event.target.value;
    setFilter(value);

    if (value === 'all') {
      dispatch(getProducts());
    }
  };

  const clickSelectCategoriesButton = (categories: ICategoryState[]) => {
    const requestData = ProductsTranslator.toProductListByCategory(categories);
    dispatch(getProductsByCategory(requestData));
  };

  const sendSearchRequest = (value: string) => {
    dispatch(getProductsBySearch(value));
  };

  return (
    <Modal
      deep={deep}
      active={active}
      name={ModalNames.List}
      expand={true}
      onClose={onClose}
      propsStyles={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        backgroundColor: theme.background,
      }}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <div className={css.searchLine}>
          <Button
            icon="arrow-left"
            propsStyles={{ width: 38, height: 38, background: theme.colorDarkGrey, padding: 0 }}
            extend={css.backButton}
            hasGradient={false}
            onClick={onClose}
          />
          <Search sendSearchRequestFn={sendSearchRequest} />
        </div>
        <div className={css.pillsWrapper}>
          <Pill
            name="products-filter"
            type="radio"
            value="all"
            text={t('modalListProducts.all')}
            amount={314}
            checked={filter === 'all'}
            onChange={handleChangePill}
          />
          <PillCategories
            name="products-filter"
            type="radio"
            text={t('modalListProducts.category')}
            amount={307}
            checked={filter === 'category'}
            clickSelectCategories={clickSelectCategoriesButton}
            onChange={handleChangePill}
          />
          <Pill
            name="products-filter"
            type="radio"
            value="without-category"
            text={t('modalListProducts.noCategory')}
            amount={1}
            checked={filter === 'without-category'}
            onChange={handleChangePill}
          />
          <Pill
            name="products-filter"
            type="radio"
            value="hidden"
            text={t('modalListProducts.hidden')}
            checked={filter === 'hidden'}
            onChange={handleChangePill}
          />
        </div>
      </div>
      <div className={css.modalInner}>
        {networkStatus === NetworkStatus.Loading ? (
          <div className={css.spinnerWrapper}>
            <Spinner />
          </div>
        ) : (
          <div className={css.productsWrapper}>
            {products.map(el => (
              <ProductsCard
                product={el as unknown as IProduct} // todo fixme
                key={el.id}
                isActive={selectedProducts.some(productId => productId === String(el.id))}
                onClick={onClickProduct}
              />
            ))}
          </div>
        )}

        <div className={css.footer}>
          <Button
            text={
              selectedProducts.length > 0
                ? tPlural('modalListProducts.addCountProducts', selectedProducts.length)
                : `${t('modalListProducts.addProducts')}`
            }
            disabled={selectedProducts.length === 0}
            propsStyles={{ width: '100%' }}
            type="button"
            onClick={onClickAddProducts}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalListProducts);
