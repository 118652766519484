import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalProductSuccessfulCreatedRules } from './ModalProductSuccessfulCreated.style';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import { Button } from 'src/components/UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

interface IProps {
  active: boolean;
  showInCatalog: boolean;
  onClose: () => void;
}

export const   ModalProductSuccessfulCreated: FC<IProps> = memo(
  function ModalProductSuccessfulCreated(props) {
    const { active, showInCatalog, onClose } = props;

    // const modalHeaderRef = useRef<HTMLDivElement>(null);
    const { css } = useStyle(ModalProductSuccessfulCreatedRules);
    const { theme } = useTheme();
    const { mainColor, colorBlack, colorGrey } = theme;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const reload = () => {
      window.location.reload();
    };

    const clickOk = () => {
      navigate('/products');
    };

    return (
      <Modal
        name={ModalNames.SettingStory}
        active={active}
        propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
        onClose={onClose}
      >
        <div className={css.modalInner}>
          <div className={css.banner}>
            <div className={css.iconWrapper}>
              <Icon name="guard" properties={{ fill: mainColor, className: css.guard }} />
              <Icon name="check" properties={{ fill: mainColor, className: css.check }} />
            </div>
            <Text
              mod="title"
              text={
                showInCatalog
                  ? t('modalProductSuccessfulCreated.productCreatedAndAvailableInTheCatalog')
                  : t('modalProductSuccessfulCreated.productCreatedAndHiddenFromTheCatalog')
              }
              fontSize={20}
              lineHeight={26}
            />
          </div>
          <Button
            text={t('modalProductSuccessfulCreated.createAnotherProduct')}
            propsStyles={{ width: '100%', background: colorGrey, color: colorBlack }}
            type="button"
            onClick={reload}
            hasGradient={false}
          />
          <div className={css.footer}>
            <Button
              text={t('modalProductSuccessfulCreated.okay')}
              propsStyles={{ width: '100%' }}
              type="button"
              onClick={clickOk}
            />
          </div>
        </div>
      </Modal>
    );
  },
);
