import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IModalErrorOrSuccessInfoRuleKeys {
  iconWrapper: {};
  guard: {};
  icon: {};
  title: {};
}

export type IModalErrorOrSuccessInfoRules = IRuleFn<
  IModalErrorOrSuccessInfoRuleKeys
>;

const ModalErrorOrSuccessInfoRuleFn: IModalErrorOrSuccessInfoRules = () => {

  return {
    iconWrapper: {
      flexShrink: 0,
      marginBottom: 24,
      position: 'relative',
      width: 80,
      height: 105,
    },
    guard: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    icon: {
      width: 35,
      height: 30,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    title: {
      marginBottom: 8,
    },
  };
};

export const ModalErrorOrSuccessInfoRules = createCachedStyle(ModalErrorOrSuccessInfoRuleFn);
