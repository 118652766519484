import { ITheme } from './theme.interface';

export const themeLight: ITheme = {
  background: '#f0f0f0',
  colorBlack: '#1c1916',
  mainColor: 'transparent',
  colorWhite: '#ffffff',
  colorGrey: '#dedede',
  colorRed: '#E42222',
  colorGreenSuccess: '#19BD16',
  colorDarkGreen: '#226620',
  colorBlueDark: '#0500FF',
  colorPink: '#FF2B55',
  colorBlue:  '#017CF3',
  colorGreen: '#34C659',
  colorGreenDark: '#31BC55',
  colorGreenLight: '#35C650',
  colorOrange: '#FF9300',
  colorOrangeLight: '#FF8F04',
  colorBlueLight: '#38ABD7',
  colorRedLight: '#FF382E',
  colorPurple: '#A153CB',
  colorPurpleLight: '#BB6FC7',
  colorGray: '#8C8C8E',
  colorGrayLight: '#A0A0A0',
  colorYellow: '#FFCC02',






  colorDarkGrey: '#a0a0a0',
  gradientBlue:
    'linear-gradient(110deg,rgba(9, 5, 171, 1) 0%,rgba(7, 3, 213, 1) 50%,rgba(78, 76, 181, 1) 75%)',

  // rgb
  colorRgbMain: '5, 0, 255',
  colorRgbRed: '228, 34, 34',
  colorRgbWhite: '255, 255, 255',
  colorRgbBlack: '28, 25, 22',

  transitionDuration: '.3s',
  appMaxWidth: 750,
  appFooterHeight: 86,
  sidePadding: 16,
  defaultFont: 'Montserrat, sans-serif',
};
// background: "radial-gradient(100% 3060.59% at 0% 0%, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0.153304) 48.9%, rgba(191, 191, 191, 0.4) 100%)" /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
