import { memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Icon } from '../Icon/Icon';
import { PillRules } from '../Pill/pill.style';
import {
  ICategoryState,
  ModalProductCategories,
} from 'src/components/ModalProductCategories/ModalProductCategories';
import { useModalState } from 'src/hooks/useModalState';

interface IProps {
  extend?: string;
  text: string;
  amount?: number;
  type: 'radio' | 'checkbox';
  name: string;
  checked: boolean;
  clickSelectCategories: (categories: ICategoryState[]) => void;
  removeFn?: () => void;
  onChange?: (event: any) => void;
}

const PillCategories = function Pill(props: IProps) {
  const { name, type, amount, checked, onChange, clickSelectCategories, text } = props;
  const { css } = useStyle(PillRules, {checked});
  const pillRef = useRef(null);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [categoryState, setCategoryState] = useState<ICategoryState[]>([]);

  // console.log(categoryState);

  useEffect(() => {
    const pillEl = pillRef.current;
    if (pillEl) {
      pillEl.addEventListener('click', openModal);
    }

    return () => {
      pillEl.removeEventListener('click', openModal);
    };
  }, [openModal]);

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      <label className={css.pill} ref={pillRef}>
        <input
          type={type}
          className={css.input}
          name={name}
          value="category"
          checked={checked}
          onChange={handleChange}
        />
        <span className={css.text}>{text? text : 'Категория'}</span>
        {(amount || amount === 0) && <span className={css.amount}>{amount}</span>}
        <Icon name="chevron-right" properties={{ className: css.arrowIcon }} />
      </label>
      {renderModal && (
        <ModalProductCategories
          active={activeModal}
          categoryState={categoryState}
          setCategoryState={setCategoryState}
          clickSelectCategories={clickSelectCategories}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default memo(PillCategories);
