import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalListRuleKeys {
  header: {};
  modalInner: {};
}

export type IModalListRules = IRuleFn<IModalListRuleKeys>;

const modalListRuleFn: IModalListRules = () => {

  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 20,
      paddingBottom: 12,
    },
    modalInner: {
      overflow: 'auto',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 40,
    },
  };
};

export const modalListRules = createCachedStyle(modalListRuleFn);
