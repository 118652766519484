import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IAccentColorRuleKeys {
  template: {};
  templateTop: {};
  templateBottom: {};
  templateButton: {};
  templateSubCategory: {};
  templateButtonSmall: {};
  templatePrice1: {};
  templatePrice2: {};
  colors: {};
  colorsInner: {};
  color: {};
  btnColor: {};
  colorPicker: {};
  spinnerWrapper: {};
}

export type IAccentColorRules = IRuleFn<IAccentColorRuleKeys>;

const AccentColorRuleFn: IAccentColorRules = props => {
  const { colorWhite, mainColor } = props.theme;

  return {
    template: {
      borderRadius: 20,
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: colorWhite,
      paddingTop: '87.47%',
    },
    templateTop: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: 260,

      '& img': {
        display: 'block',
        width: '100%',
        objectFit: 'cover',
      },
    },
    templateBottom: {
      zIndex: 0,
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: 260,

      '& img': {
        display: 'block',
        width: '100%',
        objectFit: 'cover',
      },
    },
    templateButton: {
      position: 'absolute',
      top: '60%',
      left: '8%',
      width: '83%',
      height: 38,
      backgroundColor: mainColor,
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '10px',
      fontWeight: 600,
      color: colorWhite,

      '::before': {
        content: '""',
        borderRadius: 6,
        display: 'block',
        position: 'absolute',
        inset: 0,
        background:
          'linear-gradient(90deg, rgba(255,255,255, 0) 25%, rgba(255,255,255,0.2246393557422969) 50%, rgba(255,255,255,0.2046393557422969) 100%);',
      },
    },

    templateButtonSmall: {
      position: 'absolute',
      bottom: '53%',
      right: '10%',
      width: 24,
      height: 24,
      padding: 6,
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: mainColor,

      '& svg': {
        fill: colorWhite,
      },
    },

    templateSubCategory: {
      position: 'absolute',
      bottom: '26%',
      left: '9%',
      color: mainColor,
    },

    templatePrice1: {
      position: 'absolute',
      top: '22%',
      left: '77%',
    },
    templatePrice2: {
      position: 'absolute',
      top: '28%',
      left: '77%',
      color: mainColor,
    },

    spinnerWrapper: {
      zIndex: 2,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: colorWhite,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    colors: {
      marginTop: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: 8,
    },

    colorsInner: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },

    colorPicker: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },

    color: {
      display: 'block',
      position: 'relative',
      width: 36,
      height: 36,
      borderRadius: '50%',

      '& span': {
        width: 10,
        height: 10,
        backgroundColor: colorWhite,
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },

      '& input': {
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
      },
    },

    btnColor: {
      borderRadius: '50%',
    },
  };
};

export const AccentColorRules = createCachedStyle(AccentColorRuleFn);
