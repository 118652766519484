import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IToastComponentKeys {
  toastWrp: {};
  toastText: {};
}

interface IToastComponentProps {
  positionX: 'left' | 'right',
  positionY: 'top' | 'bottom'
}

export type IToastComponent = IRuleFn<IToastComponentKeys, IToastComponentProps>;

const ToastComponentRuleFn: IToastComponent = props => {
  const {positionX, positionY} = props;
  const { colorWhite, colorGreenSuccess } = props.theme;
  return {
    toastWrp: {
      position: 'relative',
      zIndex: 1,
    },
    toastText: {
      position: 'absolute',
      left: positionX === 'left' ? 0 : 'auto',
      right: positionX === 'right' ? 0 : 'auto',
      bottom: positionY === 'top' ? 'calc(100% + 10px)' : 'auto',
      top: positionY === 'bottom' ? 'calc(100% + 10px)' : 'auto',
      zIndex: 1,
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px 0px',
      backgroundColor: colorWhite,
      padding: 10,
      borderRadius: 8,
      fontSize: 12,
      color: colorGreenSuccess,
      fontWeight: 'bold'
    }
  };
};

export const ToastComponentRules = createCachedStyle(ToastComponentRuleFn);
