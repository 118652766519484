import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISettingTextBlocksPageKeys {
  wrapper: {};
  header: {};
  container: {};
  textBlocksWrapper: {};
}

// interface ISettingTextBlocksPageProps {}

export type ISettingTextBlocksPage = IRuleFn<ISettingTextBlocksPageKeys>;

const SettingTextBlocksPageRuleFn: ISettingTextBlocksPage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(16, 16, 20), 
    },
    wrapper: {},
    container: {
      overflow: 'hidden',
    },
    textBlocksWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      paddingBottom: '20px',
    },
  };
};

export const SettingTextBlocksPageRules = createCachedStyle(SettingTextBlocksPageRuleFn);
