import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface INoSettingsKeys {
  wrapper: {};
  icon: {};
  title: {};
  text: {};
}

interface INoSettingsProps {
  headerHeight: number | undefined;
  footerHeight: number | undefined;
}

export type INoSettings = IRuleFn<INoSettingsKeys, INoSettingsProps>;

const NoSettingsRuleFn: INoSettings = props => {
  const { mainColor, colorDarkGrey } = props.theme;
  const { headerHeight, footerHeight } = props;

  const calcMinHeight = Boolean(headerHeight) || Boolean(footerHeight);

  return {
    wrapper: {
      minHeight: calcMinHeight ? `calc(100vh - ${headerHeight}px - ${footerHeight}px)` : 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    icon: {
      width: 100,
      height: 100,
      marginBottom: 12,
      fill: mainColor,
    },
    title: {
      marginBottom: 8,
    },
    text: {
      color: colorDarkGrey,
      maxWidth: 295
    },
  };
};

export const NoSettingsRules = createCachedStyle(NoSettingsRuleFn);
