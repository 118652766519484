import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { NewsLetterFormRules } from './NewsLetterForm.style';
import { ImageLoader } from '../UI/ImageLoader/ImageLoader';
import { Input } from '../UI/Input/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import Container from '../Container/Container';
import { Textarea } from '../UI/Textarea/Textarea';
import { Button } from '../UI/Button/Button';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import { useModalState } from '../../hooks/useModalState';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { RoutePath } from '../../routing/routeConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { createNewsLetter, newslettersSelector, resetNetworkStatus } from '../../redux/slices/newslettersSlice';
import { getStatistic, statisticSelector } from '../../redux/slices/statisticsSlice';
import { NetworkStatus } from '../../utils/network/network.constant';

interface IFormNewsLetter {
  title: string;
  text: string;
}

export const NewsLetterForm: FC = memo(function NewsLetterForm(props) {
  const {} = props;
  const navigate = useNavigate();
  const { css } = useStyle(NewsLetterFormRules);
  const { t, tPlural } = useTranslation();
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [imageFile, setImageFile] = useState<null | File>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [confirmSubmit, setConfirmSubmit] = useState(false)
  const dispatch = useAppDispatch();
  const { currentStatistics, networkStatus } = useAppSelector(statisticSelector);
  const { createNewsletterNetworkStatus } = useAppSelector(newslettersSelector)
  const [numberUsers, setNumberUsers] = useState(0)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      text: '',
    },
  });

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getStatistic())
    }
  }, [
    networkStatus,
    dispatch
  ]);

  useEffect(() => {
    if (currentStatistics) setNumberUsers(currentStatistics.client.value)
  }, [currentStatistics]);

  const onSubmit: SubmitHandler<IFormNewsLetter> = useCallback(data => {
    const { text, title } = data;

    if(!imageFile) {
      return;
    }

    if(!confirmSubmit) {
      openModal();
      return;
    }

    dispatch(createNewsLetter({imageFile, text, title, total: numberUsers}))
  }, [
    dispatch,
    confirmSubmit,
    numberUsers,
    imageFile,
    openModal
  ])

  useEffect(() => {
    if(confirmSubmit) {
      handleSubmit(onSubmit)();
    }
  }, [
    confirmSubmit,
    handleSubmit,
    onSubmit,
  ]);

  useEffect(() => {
    if (createNewsletterNetworkStatus === NetworkStatus.Done) {
      closeModal();
      dispatch(resetNetworkStatus('createNewsletterNetworkStatus'));
      navigate(RoutePath.Newsletters);
    }
    else if (createNewsletterNetworkStatus === NetworkStatus.Failed) {
      closeModal();
    }
  }, [
    dispatch,
    navigate,
    closeModal,
    createNewsletterNetworkStatus
  ]);

  const confirmSubmission = () => {
    setConfirmSubmit(true);
  }

  const onChangeFile = (file: File) => {
    setImageFile(file);
  };

  return <div className={css.wrapper}>
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <Container>
        <ImageLoader
          name="newsletter-image"
          propsStyles={{width: 160, height: 160, borderRadius: '8px'}}
          message={t('newsletterForm.minimumResolution', '1033×1033')}
          onChangeFile={onChangeFile}
          extend={css.imageLoader}
        />
        <div className={css.fieldWrapper}>
          <Input
            {...register('title', {required: t('newsletterForm.fillInTheField')})}
            type="text"
            label={t('newsletterForm.title')}
            controlled={false}
            placeholder={t('newsletterForm.enterTitle')}
            maxLength={100}
            errorMessage={errors.title?.message}
            labelTextTransform='uppercase'
          />
          <Textarea
            {...register('text', {required: t('newsletterForm.fillInTheField')})}
            label={t('newsletterForm.text')}
            controlled={false}
            placeholder={t('newsletterForm.enterText')}
            maxLength={1000}
            errorMessage={errors.text?.message}
            labelTextTransform='uppercase'
          />
        </div>
        <div className={css.footer}>
          <Button
            text={t('newsletterForm.sendNewsletter')}
            propsStyles={{width: '100%'}}
            type="submit"
          />
        </div>
      </Container>
    </form>
    {renderModal
      && <ModalConfirm
        onClose={closeModal}
        active={activeModal}
        confirmAction={confirmSubmission}
        title={`${tPlural('newsletterForm.usersWillReceiveTheNewsletter', numberUsers)}\n${t('newsletterForm.sendNewsletterQuestion')}`}
        subtitle={t('newsletterForm.itCantBeChangedAfterSending')}
        confirmTxt={t('newsletterForm.send')}
        cancelTxt={t('newsletterForm.cancel')}
      />}
  </div>;
});
