import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IIconInBoxKeys {
  iconBox: {};
}

interface IIconInBoxProps {
  width: number;
  height: number;
  iconFillColor: string;
  backgroundColor: string;
}

export type IIconInBox = IRuleFn<IIconInBoxKeys, IIconInBoxProps>;

const IconInBoxRuleFn: IIconInBox = props => {
  const { mainColor, colorWhite } = props.theme;
  const { width, height, backgroundColor, iconFillColor = colorWhite } = props;

  return {
    iconBox: {
      width,
      height,
      borderRadius: 8,
      backgroundColor: backgroundColor ?? mainColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        width: '60%',
        fill: iconFillColor,
      },
    },
  };
};

export const IconInBoxRules = createCachedStyle(IconInBoxRuleFn);
