import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { maintenanceEnabledFormRules } from './MaintenanceEnabledForm.style';
import { VALIDATION_ERRORS } from 'src/types/validation';
import { useTheme } from 'src/utils/theme/useTheme';
import { SubmitHandler, useForm, useWatch  } from 'react-hook-form';
import { Button } from '../UI/Button/Button';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { wizardStateSelector, resetNetworkStatus } from 'src/redux/slices/wizardSlice';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import { NetworkStatus } from 'src/utils/network/network.constant';
import Toggler from '../UI/Toggler/Toggler';
import Text from 'src/components/UI/Text/Text';
import { Textarea } from '../UI/Textarea/Textarea';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IForm {
  maintenanceMessage: string;
  maintenanceEnabled: boolean;
}

interface Iprops {
  onSubmitCallback?: () => void
}

export const MaintenanceEnabledForm: FC<Iprops> = memo(function MaintenanceEnabledForm(props) {
  const { css } = useStyle(maintenanceEnabledFormRules);
  const {onSubmitCallback} = props
  const { theme } = useTheme();
  const dispatch = useAppDispatch();

  const { wizard, saveNetworkStatus } = useAppSelector(wizardStateSelector);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      // TODO https://app.clickup.com/t/86c06uzbb разобраться почему ругается на темплейт стринг
      maintenanceMessage: wizard.maintenanceMessage? wizard.maintenanceMessage  : `${t('storeSetupPage.supportIsHereContactUsIfYouHaveAnyQuestions')}`,
      maintenanceEnabled: wizard.maintenanceEnabled,
    },
  });

  const maintenanceEnabled = useWatch({
    control,
    name: 'maintenanceEnabled',
  });

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      dispatch(resetNetworkStatus('saveNetworkStatus'))
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false)
        onSubmitCallback()
      }, 1500);
    }
  }, [saveNetworkStatus, dispatch, onSubmitCallback]);

  const onSubmit: SubmitHandler<IForm> = data => {
    const { maintenanceMessage, maintenanceEnabled } = data;
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      // 'end',
      { ...wizard, maintenanceMessage, maintenanceEnabled },
      true,
    );

    dispatch(
      wizardSaveState({
        requestData: wizardStateRequest,
      }),
    );
  };

  const disableStoreFunction = event => {
    setValue('maintenanceEnabled', event.target.checked);
  };


  return (
    <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={css.wrapperJustify}>
        <Text text={t('maintenanceEnabledForm.temporarilyDisableTheStore')} mod="title" fontSize={12} />
        <Toggler
          disabled={saveNetworkStatus === NetworkStatus.Loading}
          name={'disable-store'}
          checked={maintenanceEnabled}
          value="show-in-catalog"
          onChange={disableStoreFunction}
        />
      </div>
      <div className={css.textBlock}>
        <Textarea
          {...register('maintenanceMessage', { required: VALIDATION_ERRORS.REQUIRED })}
          label={t('maintenanceEnabledForm.textForCustomers')}
          controlled={false}
          letterCounter={true}
          placeholder={t('maintenanceEnabledForm.enterTheMessageThatCustomersWillSeeWhenTheyFirstLaunchTheBot')}
          propsStyles={{ backgroundColor: theme.background }}
          errorMessage={errors.maintenanceMessage?.message}
          maxLength={512}
        />
      </div>
      <Button
        disabled={saveNetworkStatus === NetworkStatus.Loading}
        successfully={savedSuccessfully}
        text={t('maintenanceEnabledForm.save')}
        propsStyles={{ width: '100%' }}
        type="submit"
      />
    </form>
  );
});
