import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { createAppAsyncThunk } from '../../hooks/redux';
import { NewsLetter } from '@teleport/schemas-protobuf/port/v1/port_newsletter_pb';

interface INewslettersSlice {
  newsLetters: NewsLetter[];
  newslettersListStatus: NetworkStatus;
  createNewsletterNetworkStatus: NetworkStatus;
}

interface INewsLetter {
  title: string;
  text: string;
  total: number;
  imageFile: File;
}

export const getNewsLetterList = createAppAsyncThunk(
  'newsletters/getNewsLetterList',
  async (_, thunkAPI) => {
    return await thunkAPI.extra.portOwnerApi().newsLetterList({})
  },
);

export const createNewsLetter = createAppAsyncThunk('newsletters/createNewsLetter',
  async (data: INewsLetter, thunkAPI) => {
    thunkAPI;
    let imageUrls: string[] = [];
    if (data.imageFile) {
      try {
        imageUrls = await thunkAPI.extra.filesUpload([data.imageFile]);
      } catch (e) {
        console.info('Upload files failed: ', e);
      }
    }

    const newsletter = new NewsLetter({
      title: data.title,
      messageText: data.text,
      imageUrl: imageUrls[0],
      uuid: '',
      statistic: {
        total: data.total,
      }
    })

    return await thunkAPI.extra.portOwnerApi().newsLetterUpsert({ newsletter })
  })

const initialState: INewslettersSlice = {
  newsLetters: [],
  newslettersListStatus: NetworkStatus.None,
  createNewsletterNetworkStatus: NetworkStatus.None,
};

export const newslettersSlice = createSlice({
  name: 'Newsletters',
  initialState,
  reducers: {
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'newslettersListStatus' | 'createNewsletterNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(getNewsLetterList.pending, state => {
      state.newslettersListStatus = NetworkStatus.Loading;
    });
    builder.addCase(getNewsLetterList.fulfilled,(state, action) => {
      state.newsLetters = action.payload.newsletters;
      state.newslettersListStatus = NetworkStatus.Done;
    });
    builder.addCase(getNewsLetterList.rejected, state => {
      state.newslettersListStatus = NetworkStatus.Failed;
    });

    builder.addCase(createNewsLetter.pending, state => {
      state.createNewsletterNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(createNewsLetter.fulfilled,state => {
      state.createNewsletterNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(createNewsLetter.rejected, state => {
      state.createNewsletterNetworkStatus = NetworkStatus.Failed;
    });


  },
});

export const { resetNetworkStatus } = newslettersSlice.actions;
export const newslettersSelector = (state: RootState) => state[newslettersSlice.name];
