import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from '../../../utils/applyCSSProperty ';

export interface IWizardShopNameRuleKeys {
  imagePicker: {};
  fieldWrapper: {};
}

export type IWizardShopNameRules = IRuleFn<IWizardShopNameRuleKeys>;

const WizardShopNameRuleFn: IWizardShopNameRules = props => {
  const {} = props.theme;

  return {
    imagePicker: {
      marginBottom: 10
    },
    fieldWrapper: {
      ...padding(12, 0),
    }
  };
};

export const WizardShopNameRules = createCachedStyle(WizardShopNameRuleFn);
