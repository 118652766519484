import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IPillRuleKeys {
  pill: {};
  input: {};
  text: {};
  amount: {};
  arrowIcon: {};
}

interface IPillRuleProps {
  checked: boolean;
}

export type IPillRules = IRuleFn<IPillRuleKeys, IPillRuleProps>;

const PillRuleFn: IPillRules = props => {
  const { colorGrey, colorBlack, colorDarkGrey, colorWhite } = props.theme;
  const { checked} = props;
  return {
    pill: {
      position: 'relative',
      display: 'flex',
      columnGap: 4,
      alignItems: 'center',
      ...padding(4, 12),
      borderRadius: '4px',
      backgroundColor: checked? colorBlack : colorGrey,
      color: checked ? colorWhite : 'inherit'
    },
    input: {
      position: 'absolute',
      zIndex: '-1',
      opacity: 0,
    },
    text: {
      whiteSpace: 'nowrap',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
    },
    amount: {
      color: colorDarkGrey,
      fontSize: 12,
      fontWeight: '500px',
      lineHeight: '16px',
    },
    arrowIcon: {
      width: 12,
      height: 12,
      fill: colorDarkGrey,
      transform: 'rotate(90deg)',
    },
  };
};

export const PillRules = createCachedStyle(PillRuleFn);
