import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface ITogglerRuleKeys {
  toggler: {};
  input: {};
}

interface ITogglerProps {
  disabled: boolean;
  checked: boolean;
}

export type ITogglerRules = IRuleFn<ITogglerRuleKeys, ITogglerProps>;

const togglerRuleFn: ITogglerRules = props => {
  const { colorDarkGrey, colorWhite, mainColor } = props.theme;
  const { disabled, checked } = props;
  return {
    toggler: {
      pointerEvents: disabled ? 'none' : 'all',
      opacity: disabled ? '0.7' : '1',
      position: 'relative',
      display: 'inline-block',
      flexShrink: 0,
      borderRadius: 100,
      backgroundColor: checked? mainColor : colorDarkGrey,
      width: 40,
      height: 22,
      transitionDuration: '0.2s',
      transitionTimingFunction: 'linear',
      transitionProperty: 'background-color',
      transform: 'translate3d(0)',
      zIndex: 0,

      '::before': {
        position: 'absolute',
        left: checked ? 'calc(100% - 21px)' : 1,
        top: '50%',
        transform: 'translateY(-50%)',
        content: '""',
        display: 'block',
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: colorWhite,
        transitionDuration: '0.2s',
        transitionTimingFunction: 'linear',
        transitionProperty: 'left',
      },

    },
    input: {
      position: 'absolute',
      zIndex: '-1',
      opacity: 0,
    },
  };
};

export const togglerRules = createCachedStyle(togglerRuleFn);
