import { FC, memo, useCallback } from 'react';
import { Step } from 'src/components/Step/Step';
import { useStyle } from 'src/utils/theme/useStyle';
import { WizardProductTypeRules } from './WizardProductType.style';
import { ListStoreFocus } from 'src/components/UI/Lists/ListStoreFocus/ListStoreFocus';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { useProductTypeList } from '../../../components/UI/Lists/ListStoreFocus/useProductTypeList';
import { setStoreFocus, wizardStateSelector } from '../../../redux/slices/wizardSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { StoreFocus } from '../../../types/wizard';

export const WizardProductType: FC = memo(function WizardProductType() {
  const { wizard: { storeFocus } } = useAppSelector(wizardStateSelector);
  const { css } = useStyle(WizardProductTypeRules);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const productTypeList = useProductTypeList();

  const onChangeListValue = useCallback((value: StoreFocus) => {
    dispatch(setStoreFocus(value));
  }, [dispatch]);

  return (
    <Step
      stepNumber={3}
      title={t('wizardStepProductType.chooseStoreType')}
    >
      <div className={css.wrapperForList}>
        <ListStoreFocus
          title={t('wizardStepProductType.products')}
          name="productType"
          options={productTypeList}
          placeholder={t('wizardStepProductType.chooseFromTheList')}
          onChange={onChangeListValue}
          selectedValue={storeFocus.value}
        />
        <ListStoreFocus
          title={t('wizardStepProductType.services')}
          name="serviceType"
          options={[]}
          disabled={true}
          placeholder={t('wizardStepProductType.willBeAvailableSoon')}
          soon={true}
        />
        <ListStoreFocus
          name="courseType"
          title={t('wizardStepProductType.courses')}
          options={[]}
          disabled={true}
          placeholder={t('wizardStepProductType.willBeAvailableSoon')}
          soon={true}
        />
        <ListStoreFocus
          name="eventType"
          title={t('wizardStepProductType.eventsAndActivities')}
          options={[]}
          disabled={true}
          placeholder={t('wizardStepProductType.willBeAvailableSoon')}
          soon={true}
        />
      </div>
    </Step>
  );
});
