export function formatTime(timestamp: number): string {

  if (timestamp.toString().length === 10) {
    timestamp *= 1000;
  }

  const date = new Date(timestamp);

  return date.toISOString().slice(11, 16);
}
