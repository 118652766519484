import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IWizardStartRuleKeys {
  inner: {};
  iconWrapper: {};
  bookmark: {};
  check: {};
  title: {};
  top: {};
  bottom: {};
  free: {};
  freeTitle: {};
  freeSubtitle: {};
  promocodeBanner: {};
  promocodeValue: {};
  promocodeTitle: {};
  btnRemoveCode: {};
  promocode: {};
}

interface IWizardStartRuleProps {
  appFooterHeight: number;
}

export type IWizardStartRules = IRuleFn<IWizardStartRuleKeys, IWizardStartRuleProps>;

const WizardStartRuleFn: IWizardStartRules = props => {
  const { colorWhite, colorGrey, colorBlack } = props.theme;
  const { appFooterHeight } = props;

  return {
    inner: {
      paddingTop: 40,
      paddingBottom: 24,
      minHeight: `calc(100vh - ${appFooterHeight}px)`,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    iconWrapper: {
      marginBottom: '30px',
      position: 'relative',
      width: 73,
      height: 85,
    },
    bookmark: {
      position: 'absolute',
      left: 0,
      top: 0,
    },
    check: {
      position: 'absolute',
      left: '50%',
      top: '45%',
      transform: 'translate(-50%, -50%)',
    },
    title: {
      marginBottom: 8,
    },
    top: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    bottom: {},
    promocodeBanner: {
      marginTop: '36px',
      borderRadius: 12,
      ...padding(16),
      backgroundColor: colorWhite,
    },
    promocodeValue: {
      textAlign: 'center',
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: `1px solid ${colorGrey}`,
    },
    promocodeTitle: {
      color: colorBlack,
      opacity: 0.5,
      marginBottom: 2,
    },
    promocode: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 4,
    },
    btnRemoveCode: {
      width: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: colorGrey,
      padding: 0,
      borderRadius: '50%',
      border: 'none',
      outline: 'none',
    },
    free: {
      marginTop: 24,
      textAlign: 'center',
      ...padding(24, 16),
      backgroundColor: colorWhite,
      borderRadius: 12,
    },
    freeTitle: {
      marginBottom: 8,
    },
    freeSubtitle: {
      opacity: 0.5,
    },
  };
};

export const WizardStartRules = createCachedStyle(WizardStartRuleFn);
