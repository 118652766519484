import { IRuleFn } from '../../../utils/theme/fela.interface';
import { createCachedStyle } from '../../../utils/theme/createCachedStyle';

export interface IStatisticsPeriodKeys {
  period: {};
}

export interface IStatisticsPeriodProps {
  isActive: boolean;
}

export type IStatisticsPeriod = IRuleFn<IStatisticsPeriodKeys, IStatisticsPeriodProps>;

const StatisticsPeriodRuleFn: IStatisticsPeriod = props => {
  const { colorBlack } = props.theme;
  const { isActive } = props;

  return {
    period: {
      flexGrow: 1,
      fontWeight: 800,
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: '18px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 4,
      borderBottom: `2px solid ${colorBlack}`,
      opacity: isActive ? 1 : .3,
      transition: 'opacity 0.2s linear',
    },
  };
};

export const StatisticsPeriodRules = createCachedStyle(StatisticsPeriodRuleFn);
