import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IModalConfirmRuleKeys {
  title: {};
  textWrapper: {};
  btnsWrp: {};
  separator: {};
}

export type IModalConfirmRules = IRuleFn<IModalConfirmRuleKeys>;

const ModalConfirmRuleFn: IModalConfirmRules = props => {
  const { colorRgbBlack } = props.theme;

  return {
    textWrapper: {
      textAlign: 'center',
      ...padding(16),
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
    },
    title: {
      fontSize: 15,
      lineHeight: '22px',
      fontWeight: 600,
      letterSpacing: '-0.41px',
      whiteSpace: 'pre-wrap',
    },
    btnsWrp: {
      borderTop: `1px solid rgba(${colorRgbBlack}, 0.36)`,
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        padding: 11,
        textAlign: 'center',
        width: '100%',
      },
      '& button span': {
        fontSize: 15,
        textAlign: 'center',
      },
    },
    separator: {
      width: '1px',
      background: `rgba(${colorRgbBlack}, 0.36)`,
      flexShrink: '0',
    },
  };
};

export const ModalConfirmRules = createCachedStyle(ModalConfirmRuleFn);
