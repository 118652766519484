import { FC, memo, useMemo } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { StatisticsCardRules } from './StatisticsCard.style';
import Text from '../UI/Text/Text';
import useCurrencySymbol from '../../hooks/useCurrencySymbol';
import { useAppSelector } from '../../hooks/redux';
import { wizardStateSelector } from '../../redux/slices/wizardSlice';
import { StatisticPeriod } from '@teleport/schemas-protobuf/port/v1/port_statistic_pb';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

export interface IStatisticsCard {
  id: string;
  title: string;
  value: string;
  percentages: number;
  hasCurrencySymbol: boolean;
  increase: boolean;
  period: StatisticPeriod;
}

interface IProps {
  data: IStatisticsCard
}

export const StatisticsCard: FC<IProps> = memo(function StatisticsCard(props) {
  const { t } = useTranslation();
  const { title, value, percentages, hasCurrencySymbol, increase, period } = props.data;
  const { css } = useStyle(StatisticsCardRules, { increase });
  const { wizard } = useAppSelector(wizardStateSelector)
  const currencySymbol = useCurrencySymbol(wizard.currency)
  const periodText = useMemo(() => {
    switch (period) {
      case StatisticPeriod.DAY:
        return t('statisticsCard.forTheDay')
      case StatisticPeriod.WEEK:
        return t('statisticsCard.forTheWeek')
      case StatisticPeriod.MONTH:
        return t('statisticsCard.forTheMonth')
      case StatisticPeriod.YEAR:
        return t('statisticsCard.forTheYear')
      default:
        return ''
    }
  }, [t, period])

  return (
    <div className={css.card}>
      <Text mod='text' text={title} fontWeight={700} fontSize={14} lineHeight={18} extend={css.title} />
      <div>
        <Text
          mod='text'
          text={`${value} ${hasCurrencySymbol ? currencySymbol : ''}`}
          fontWeight={500}
          fontSize={24}
          lineHeight={24}
          extend={css.value}
        />
        <Text
          text={`${increase ? '+' : ''}${percentages.toFixed(2)}% ${periodText}`}
          mod='text'
          fontSize={12}
          lineHeight={16}
          fontWeight={500}
          extend={css.percentages}
        />
      </div>
    </div>
  );
});
