import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IListRuleKeys {
  btnOpen: {};
  list: {};
  rightBlock: {};
  textWrapper: {};
}

interface IListRuleProps {
  soon: boolean;
}

export type IListRules = IRuleFn<IListRuleKeys, IListRuleProps>;

const listRuleFn: IListRules = props => {
  const { colorWhite, colorBlack } = props.theme;
  const { soon } = props;

  return {
    list: {
      pointerEvents: soon ? 'none' : 'auto',
      ...padding(16),
      backgroundColor: colorWhite,
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: '9px',
    },
    btnOpen: {
      borderRadius: 0,

      '& svg': {
        fill: colorBlack,
      },
    },
    rightBlock: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2
    }
  };
};

export const listRules = createCachedStyle(listRuleFn);
