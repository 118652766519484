import React, { FC, memo } from 'react';
import { useStyle } from '../../../utils/theme/useStyle';
import { StatisticsPeriodRules } from './StatisticsPeriod.style';
import { StatisticPeriod } from '@teleport/schemas-protobuf/port/v1/port_statistic_pb';

interface IProps {
  id: StatisticPeriod;
  isActive: boolean;
  text: string;
  onClick: (event:  React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const StatisticsPeriod: FC<IProps> = memo(function StatisticsPeriod(props) {
  const { id, onClick, text, isActive } = props;
  const { css } = useStyle(StatisticsPeriodRules, {
    isActive
  });

  return (
    <div
      id={String(id)}
      className={css.period}
      onClick={onClick}>
      {text}
    </div>
  );
});
