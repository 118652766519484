import { addPx, padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ICategoriesForProductKeys {
  header: {};
  wrapper: {};
  subcategory: {};
  spinnerWrapper: {};
}

// interface ICategoriesForProductProps {}

export type ICategoriesForProduct = IRuleFn<ICategoriesForProductKeys>;

const CategoriesForProductRuleFn: ICategoriesForProduct = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(16), 
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 12,
      paddingBottom: 12,
      rowGap: 24,
    },
    subcategory: {
      textTransform: 'uppercase',
      lineHeight: addPx(18),
      fontWeight: '800',
      fontSize: 12,
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

export const CategoriesForProductRules = createCachedStyle(CategoriesForProductRuleFn);
