import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import useLoader from 'src/hooks/useLoader';
import { Input } from 'src/components/UI/Input/Input';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { useModalState } from 'src/hooks/useModalState';
import {
  ICategoryState,
  ModalProductCategories,
} from 'src/components/ModalProductCategories/ModalProductCategories';
import { ModalModifierType } from 'src/components/ModalModifierType/ModalModifierType';
import { CardInfoBtn } from 'src/components/CardInfoBtn/CardInfoBtn';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { RoutePath } from '../../routing/routeConfig';
import { useAppDispatch } from 'src/hooks/redux';
import { setShowModifiers } from 'src/redux/slices/modifiersSlice';
import { EdiModifierPageRules } from './EditModifierPage.style';
import { useTheme } from 'src/utils/theme/useTheme';
import { ModalModifierVariants } from 'src/components/ModalModifierVariants/ModalModifierVariants';
import ModalConfirm from 'src/components/ModalConfirm/ModalConfirm';

const modifierTypes = [
  {
    id: 1,
    title: 'Переключатель',
    txt: 'Активация варианта. Например, выбор дополнительной функции',
    infoImg: 'images/modifires-info/1.png',
    infoTxt: 'Можно включить или выключить',
  },
  {
    id: 2,
    title: 'Варианты',
    txt: 'Выбор варианта из ряда. Например, выбор размера',
    infoImg: 'images/modifires-info/2.png',
    infoTxt: 'Можно выбрать один вариант из списка',
  },
  {
    id: 3,
    title: 'Список',
    txt: 'Выбор варианта из открывающегося списка. Например, выбор комплектации',
    infoImg: 'images/modifires-info/3.png',
    infoTxt: 'Можно выбрать один вариант',
  },
];

interface IVariant {
  id: number;
  txt: string;
}

//Page to edit create Modifier
export const EdiModifierPage: FC = memo(function EdiModifierPage(props) {
  const {} = props;
  const { css } = useStyle(EdiModifierPageRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const [shouldCheckPrice, setShouldCheckPrice] = useState(false);
  const [linkDescription, setLinkDescription] = useState(false);
  const [variantsList, setVariantsList] = useState<IVariant[]>([]);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [renderModal2, activeModal2, openModal2, closeModal2] = useModalState();
  const [renderModal3, activeModal3, openModal3, closeModal3] = useModalState();
  const [renderModal4, activeModal4, openModal4, closeModal4] = useModalState();
  const [renderModal5, activeModal5, openModal5, closeModal5] = useModalState();
  const { theme } = useTheme();

  //state for modifier type
  const [modifierTypeId, setModifierTypeId] = useState<number | undefined>(undefined);
  const [modifierTypeInfoId, setModifierTypeInfoId] = useState<number | undefined>(undefined);

  const [categoryState, setCategoryState] = useState<ICategoryState[]>([]);
  const clickSelectCategories = categories => {
    console.info(categories);
  };

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  useScrollToTop();
  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (!modifierTypeInfoId) return;
    openModal3();
  }, [modifierTypeInfoId, openModal3]);

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text text="Модификатор" mod="title" fontWeight={800} fontSize={20} lineHeight={22} />
        </header>
      }
      footer={
        <Footer>
          <div className={css.grid}>
            <Button
              onClick={() => {
                dispatch(setShowModifiers(true));
                navigate(RoutePath.Modifiers);
              }}
              text="Сохранить модификатор"
              propsStyles={{ width: '100%' }}
            />
          </div>
        </Footer>
      }
    >
      <Container extend={css.container}>
        <Input
          controlled={false}
          name={'modifire-name'}
          type="text"
          label={'название'}
          placeholder="Введите название модификатора"
        />
        <div>
          <ListButton
            titleUppercase={true}
            padding="12px 0"
            titleFontSize={12}
            bg="transparent"
            title={'категория товаров!'}
            placeholder={'Выберите из списка'}
            onClick={() => openModal()}
          />
          <ListButton
            text={
              modifierTypes.find(el => el.id === modifierTypeId)
                ? modifierTypes.find(el => el.id === modifierTypeId).title
                : undefined
            }
            titleUppercase={true}
            padding="12px 0"
            titleFontSize={12}
            bg="transparent"
            title={'тип'}
            placeholder={'Выберите из списка'}
            onClick={() => openModal2()}
          />
          <ListButton
            disabled={modifierTypeId === 1}
            titleUppercase={true}
            padding="12px 0"
            titleFontSize={12}
            bg="transparent"
            title={'Варианты'}
            placeholder={'Заполните варианты для выбора пользователем'}
            onClick={() => {
              openModal4();
            }}
          />
          <div className={css.justifyWrapper}>
            <Text text="Влияет на цену" mod="title" fontSize={12} />
            <Toggler
              checked={shouldCheckPrice}
              name="show-in-recomendation"
              value="show-in-recomendation"
              onChange={e => setShouldCheckPrice(e.target.checked)}
            />
          </div>
          <div className={css.justifyWrapper}>
            <Text text="Ссылка с описанием модификатора" mod="title" fontSize={12} />
            <Toggler
              checked={linkDescription}
              name="show-in-recomendation"
              value="show-in-recomendation"
              onChange={e => setLinkDescription(e.target.checked)}
            />
          </div>
          {linkDescription && (
            <Input
              controlled={false}
              name={'modifire-name'}
              type="text"
              label={'Ссылка'}
              placeholder="Введите ссылку"
              message={
                <>
                  Советуем использовать{' '}
                  <a href="https://teletype.in/" target="_blank" rel="noreferrer">
                    Teletype
                  </a>{' '}
                  чтобы пользователи не покидали магазин
                </>
              }
            />
          )}
        </div>
        <div className={css.deleteButtonWrapper}>
          <Button
            text="Удалить"
            propsStyles={{ width: '100%', background: theme.colorGrey, color: theme.colorBlack }}
            // TODO => Прикрутить модалку подтверждения удаления
            onClick={() => openModal5()}
          />
        </div>
      </Container>
      {renderModal && (
        <ModalProductCategories
          categoryState={categoryState}
          setCategoryState={setCategoryState}
          clickSelectCategories={clickSelectCategories}
          active={activeModal}
          onClose={closeModal}
        />
      )}
      {renderModal2 && (
        <ModalModifierType active={activeModal2} onClose={closeModal2}>
          {modifierTypes.map(el => {
            return (
              <CardInfoBtn
                key={el.id}
                title={el.title}
                text={el.txt}
                isActive={modifierTypeId === el.id}
                onClick={() => {
                  closeModal2();
                  setModifierTypeId(el.id);
                }}
                onInfoClick={() => setModifierTypeInfoId(el.id)}
              />
            );
          })}
        </ModalModifierType>
      )}
      {renderModal3 && (
        <ModalUniversal
          deep={2}
          title={modifierTypes.find(el => el.id === modifierTypeInfoId)?.title}
          subtitle={modifierTypes.find(el => el.id === modifierTypeInfoId)?.infoTxt}
          active={activeModal3}
          onClose={() => {
            closeModal3();
            setModifierTypeInfoId(undefined);
          }}
        >
          <div className={css.templateWrapper}>
            <img src={modifierTypes.find(el => el.id === modifierTypeInfoId)?.infoImg} alt="" />
          </div>
        </ModalUniversal>
      )}
      {renderModal4 && (
        <ModalModifierVariants
          active={activeModal4}
          onClose={closeModal4}
          variantsArr={variantsList}
          setVariantsArr={setVariantsList}
        />
      )}
      {renderModal5 && (
        <ModalConfirm
          title={'Удалить модификатор?'}
          active={activeModal5}
          onClose={closeModal5}
          confirmAction={() => false}
        />
      )}
    </Layout>
  );
});
