import { FC, memo, useMemo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { SettingButtonRules } from './SettingButton.style';
import { Icon, IconList } from '../Icon/Icon';
import { IconInBox } from '../IconInBox/IconInBox';
import Text from '../Text/Text';
import { Link } from 'react-router-dom';
import { PillSoon } from '../PillSoon/PillSoon';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

interface ISettingButtonProps {
  title: string;
  icon: IconList;
  href?: string;
  onClick?: () => void;
  count?: number;
  isActive?: boolean;
  isCountLoading?: boolean;
  soon?: boolean;
  backgroundColorForIcon?: string,
}

export const SettingButton: FC<ISettingButtonProps> = memo(function SettingButton(props) {
  const { icon, title, count, isActive, href = '', soon, isCountLoading, backgroundColorForIcon, onClick } = props;
  const { css } = useStyle(SettingButtonRules, { soon });
  const { t } = useTranslation();

  const countComponent = useMemo(() => {
    if (isCountLoading) {
      // todo loader
      return <div>loading</div>;
    }

    if (count !== undefined) {
      return (
        <Text
          mod="text"
          text={count > 0 ? String(count) : t('settingsButton.none')}
          extend={css.number}
          fontWeight={600}
          fontSize={14}
          lineHeight={16}
        />
      );
    }

    return null;
  }, [isCountLoading, count, css.number, t]);

  return (
    <Link to={href} onClick={onClick} className={css.settingButton}>
      <div className={css.wrap}>
        <IconInBox name={icon} propsStyles={{ width: 24, height: 24 }} backgroundColor={backgroundColorForIcon} />
        <Text
          mod="title"
          text={title}
          fontWeight={600}
          fontSize={15}
          lineHeight={16}
          extend={css.text}
        />
      </div>
      <div className={css.wrap}>
        {soon ? (
          <PillSoon />
        ) : (
          <>
            {countComponent}
            {isActive !== undefined && (
              <Text
                mod="text"
                text={isActive ? 'settingsButton.on' : 'settingsButton.off'}
                extend={css.number}
                fontWeight={600}
                fontSize={14}
                lineHeight={16}
              />
            )}
            <Icon name="chevron-right" properties={{ className: css.iconChevron }} />
          </>
        )}
      </div>
    </Link>
  );
});
