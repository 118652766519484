import { FC, PropsWithChildren } from 'react';
import { AnalyticsContext } from './analytics.constant';
import { Analytics } from './analytics';

interface IAnalyticsProviderProps {
  analytics: typeof Analytics.instance;
}

export const AnalyticsProvider: FC<PropsWithChildren<IAnalyticsProviderProps>> = props => {
  const { children, analytics } = props;

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
