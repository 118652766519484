import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { memo, useCallback, useState } from 'react';
import { ModalStoreCreatedResultRules } from './ModalStoreCreatedResult.style';
import { Button } from '../UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import { useTelegram } from 'src/utils/telegramProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../routing/routeConfig';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import ErrorOrSuccessInfo from '../ErrorOrSuccessInfo/ErrorOrSuccessInfo';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  onClose: () => void;
  active: boolean;
  isSuccess: boolean;
}

const ModalStoreCreatedResult = function ModalStoreCreatedResult(props: IProps) {
  const { active, isSuccess, onClose } = props;
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);
  const { css } = useStyle(ModalStoreCreatedResultRules, { footerHeight });
  const { theme } = useTheme();
  const Telegram = useTelegram();
  const navigate = useNavigate();
  const location = useLocation();
  const [_, setValue] = useLocalStorage('seenCongratulationsStoreModal');
  const { t } = useTranslation();

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  const goToMainPage = () => {
    setValue(true);
    setTimeout(() => navigate(RoutePath.Main), 0);
  };

  const updatedOnCloseFn = () => {
    onClose();
    setValue(true);
    if (isSuccess && location.pathname !== RoutePath.Main) {
      setTimeout(() => navigate(RoutePath.Main), 0);
    }
  };

  return (
    <Modal
      active={active}
      name={ModalNames.StoreCreatedResult}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={updatedOnCloseFn}
    >
      <div className={css.modalInner}>
        {isSuccess
          ? (
            <ErrorOrSuccessInfo
              success={true}
              title={t('modalStoreCreatedResult.congratulations!')}
              text={t('modalStoreCreatedResult.yourStoreHasBeenCreated')}
            />
          ) : (
            <ErrorOrSuccessInfo
              success={false}
              title={t('modalStoreCreatedResult.unableToCreateStore')}
              text={t('modalStoreCreatedResult.somethingWentWrongWeAreLookingIntoItAlreadyPleaseContactUsIfYouHaveAnyQuestions')}
            />
          )
        }
        <div className={css.footer} ref={footerRef}>
          {isSuccess ? (
            <Button
              text={t('modalStoreCreatedResult.great')}
              propsStyles={{
                width: '100%',
                background: theme.mainColor,
              }}
              onClick={location.pathname === RoutePath.Main ? updatedOnCloseFn : goToMainPage}
            />
          ) : (
            <Button
              text={t('modalStoreCreatedResult.contactSupport')}
              propsStyles={{
                width: '100%',
                background: theme.colorGrey,
                color: theme.colorBlack,
              }}
              onClick={connectToSupport}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalStoreCreatedResult);
