import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IUserSlice {
  userName: string;
}

const initialState: IUserSlice = {
  userName: '',
};

// todo Тут будет экшн для получения токена

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
  },
});

export const { setUserName } = userSlice.actions;

export const userState = (state: RootState) => state[userSlice.name];
