import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { ModalStatisticsRules } from './ModalStatistics.style';
import Text from '../UI/Text/Text';
import { StatisticsPeriod } from '../UI/StatisticsPeriod/StatisticsPeriod';
import { IStatisticsCard, StatisticsCard } from '../StatisticsCard/StatisticsCard';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getStatistic, statisticSelector } from '../../redux/slices/statisticsSlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { v4 } from 'uuid';
import { StatisticPeriod } from '@teleport/schemas-protobuf/port/v1/port_statistic_pb';
import { Icon } from '../UI/Icon/Icon';
import { formatDate } from '../../utils/date/formatDate';
import { Spinner } from '../UI/Spinner/Spinner';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  active: boolean;
  onClose: () => void;
}

export const ModalStatistics: FC<IProps> = memo(function ModalStatistics(props) {
  const { active, onClose } = props;
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(ModalStatisticsRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });
  const dispatch = useAppDispatch();
  const { networkStatus, statistics } = useAppSelector(statisticSelector);
  const { t } = useTranslation();

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getStatistic());
    }
  }, [dispatch, networkStatus]);

  const statisticsCards: {
    period: StatisticPeriod,
    cards: IStatisticsCard[],
    calculatedAt: string
  }[] = useMemo(() => {
    return statistics.map(el => {
      return {
        period: el.period,
        calculatedAt: formatDate(Number(el.calculatedAt.seconds)),
        cards: [
          {
            id: v4(),
            title: t('modalStatistics.averageOrderValue'),
            hasCurrencySymbol: true,
            increase: el.orderPrice.deltaInPercent > 0,
            percentages: el.orderPrice.deltaInPercent,
            period: el.period,
            value: el.orderPrice.value.toLocaleString('ru-RU'),
          },
          {
            id: v4(),
            title: t('modalStatistics.numberOfOrders'),
            hasCurrencySymbol: false,
            increase: el.order.deltaInPercent > 0,
            percentages: el.order.deltaInPercent,
            period: el.period,
            value: el.order.value.toLocaleString('ru-RU'),
          },
          {
            id: v4(),
            title: t('modalStatistics.numberOfUsers'),
            hasCurrencySymbol: false,
            increase: el.client.deltaInPercent > 0,
            percentages: el.client.deltaInPercent,
            period: el.period,
            value: el.client.value.toLocaleString('ru-RU'),
          },
          {
            id: v4(),
            title: t('modalStatistics.revenue'),
            hasCurrencySymbol: true,
            increase: el.revenue.deltaInPercent > 0,
            percentages: el.revenue.deltaInPercent,
            period: el.period,
            value: el.revenue.value.toLocaleString('ru-RU'),
          },
        ]
      }
    })
  }, [t, statistics])

  const [statisticsPeriod, setStatisticsPeriod] = useState<StatisticPeriod>(StatisticPeriod.DAY);
  const statisticsPeriods = useMemo(() => {
    return [
      {id: StatisticPeriod.DAY, text: t('modalStatistics.day')},
      {id: StatisticPeriod.WEEK, text: t('modalStatistics.week')},
      {id: StatisticPeriod.MONTH, text: t('modalStatistics.month')},
      {id: StatisticPeriod.YEAR, text: t('modalStatistics.year')},
    ]
  }, [t]);

  const renderedStatistics = useMemo(() => {
    return statisticsCards.find(el => el.period === statisticsPeriod);
  }, [statisticsCards, statisticsPeriod]);

  const onClickPeriod = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const id = event.currentTarget.id;
    setStatisticsPeriod(Number(id));
  };

  const content = useMemo(() => {
    if (networkStatus === NetworkStatus.Loading) {
      return (
        <div className={css.spinnerWrapper}>
          <Spinner/>
        </div>
      )
    } else if (renderedStatistics) {
      return (
        <div className={css.cardWrapper}>
          {renderedStatistics.cards.map(el => <StatisticsCard data={el} key={el.id}/>)}
        </div>
      )
    } else {
      return (
        <div className={css.noStatistics}>
          <Icon name='statistics' properties={{className: css.noStatisticsIcon}}/>
          <Text text={t('modalStatistics.statisticsAreNotAvailableYet')} mod='text' fontSize={20} lineHeight={22} fontWeight={700}/>
          {renderedStatistics?.calculatedAt
            && <Text
              text={t('modalStatistics.statisticsWillBeAvailableOn', renderedStatistics?.calculatedAt)}
              mod='text'
              extend={css.noStatisticsSubtitle}
            />
          }
        </div>
      )
    }
  }, [t, css, networkStatus, renderedStatistics])

  return (
    <Modal
      deep={2}
      name={ModalNames.AccentBackground}
      active={active}
      propsStyles={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}
      onClose={onClose}
    >
      <div className={css.header} ref={modalHeaderRef}>
        <Text text={t('modalStatistics.statistics')} mod="title"/>
      </div>
      <div className={css.modalInner}>
        <div className={css.periods}>
          {statisticsPeriods.map(el =>
            <StatisticsPeriod
              id={el.id}
              key={el.id}
              isActive={el.id === statisticsPeriod}
              text={el.text}
              onClick={onClickPeriod}
            />)}
        </div>
        {content}
      </div>
    </Modal>
  );
});
