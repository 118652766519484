import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';
import { padding } from '../../utils/applyCSSProperty ';

export interface INewsLetterFormKeys {
  wrapper: {};
  fieldWrapper: {};
  imageLoader: {};
  footer: {};
}

export type INewsLetterForm = IRuleFn<INewsLetterFormKeys>;

const NewsLetterFormRuleFn: INewsLetterForm = props => {
  const { colorWhite } = props.theme;
  const {} = props;

  return {
    wrapper: {
      paddingBottom: 128
    },
    fieldWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
    },
    footer: {
      width: '100%',
      background: colorWhite,
      ...padding(12, 16, 30),
      position: 'fixed',
      left: 0,
      bottom: 0,
    },
    imageLoader: {
      marginTop: 12,
      marginBottom: 24,
    }
  };
};

export const NewsLetterFormRules = createCachedStyle(NewsLetterFormRuleFn);
