import React, { FC, memo, useEffect, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalSettingTextBlocksRules } from './ModalSettingTextBlocks.style';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from 'src/components/UI/Button/Button';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { Input } from 'src/components/UI/Input/Input';
import { Textarea } from 'src/components/UI/Textarea/Textarea';
import { ModalAccentBg } from '../ModalAccentBg/ModalAccentBg';
import { useModalState } from 'src/hooks/useModalState';
import {
  createTextBlock,
  deleteTextBlock,
  ITextBlock,
  resetSendNetworkStatus,
  textBlockState,
  updateTextBlock,
} from 'src/redux/slices/textBlockSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { checkUrl } from 'src/utils/checkUrl';
import ModalError from 'src/components/ModalError/ModalError';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useScrollModal } from 'src/hooks/useScrollModal';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { Trans } from 'src/utils/i18n/components/trans';
import { linkMaxLength } from 'src/utils/constants';
import { correctUrlOnPaste, trimAndReplaceFunction } from 'src/utils/validateUrl';

interface IProps {
  active: boolean;
  onClose: () => void;
  editTextBlock?: ITextBlock | null;
}

export const ModalSettingTextBlocks: FC<IProps> = memo(function ModalSettingTextBlocks(props) {
  const { networkStatus, sendNetworkStatus } = useAppSelector(textBlockState);
  const { active, editTextBlock, onClose } = props;
  const [renderAccentBgModal, activeAccentBgModal, openAccentBgModal, closeAccentBgModal] =
    useModalState();
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();

  const { t, tPlural } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    control,
    register,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      title: editTextBlock?.title || '',
      text: editTextBlock?.text || '',
      buttonText: editTextBlock?.button?.enabled ? editTextBlock.button.text : '',
      buttonLink: editTextBlock?.button?.enabled ? editTextBlock.button.clickUrl : '',
      accentColor: editTextBlock?.accentColor || false,
      hasButton: editTextBlock?.button?.enabled || false,
    },
  });

  // scroll modal on focus
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const hasButtonValue = watch('hasButton');
  useScrollModal(modalInnerRef, hasButtonValue);
  const hasButtonWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(hasButtonValue) {
      hasButtonWrapperRef.current?.scrollIntoView()
    }
  }, [hasButtonValue]);


  const deleteTextBlockFn = () => {
    if (!editTextBlock) return;
    const newTextBlokToDelete = {
      ...editTextBlock,
      button: {
        ...editTextBlock.button,
        text: 'TODO: remove me when Alexandr fix validation',
      },
    };

    dispatch(deleteTextBlock(newTextBlokToDelete));
  };

  const onSubmit = data => {
    const { title, text, buttonText, buttonLink, accentColor, hasButton } = data;

    if (editTextBlock?.uuid) {
      dispatch(
        updateTextBlock({
          uuid: editTextBlock.uuid,
          title: title,
          text: text,
          accentColor: accentColor,
          ...(hasButton && buttonText && buttonLink
            ? {
              button: {
                enabled: true,
                text: buttonText,
                clickUrl: buttonLink,
              },
            }
            : {}),
        }),
      )
    } else {
      dispatch(
        createTextBlock({
          title: title,
          text: text,
          accentColor: accentColor,
          ...(hasButton
            ? {
              button: {
                enabled: true,
                text: buttonText,
                clickUrl: buttonLink,
              },
            }
            : {}),
        }),
      )
    }
  };

  useEffect(() => {
    if (sendNetworkStatus === NetworkStatus.None) return
    if (sendNetworkStatus === NetworkStatus.Failed) {
      dispatch(resetSendNetworkStatus())
      openErrorModal();
    }
    if (sendNetworkStatus === NetworkStatus.Done) {
      dispatch(resetSendNetworkStatus())
      onClose();
    }
  }, [sendNetworkStatus, onClose, dispatch, openErrorModal])

  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);

  const { css } = useStyle(ModalSettingTextBlocksRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: modalFooterRef.current?.clientHeight,
  });

  const { theme } = useTheme();
  const { colorGrey, colorBlack } = theme;

  const validateFieldsIfHasButton = (fieldName: string) => {
    return value => {
      if (hasButtonValue && !value) {
        return t('modalSettingTextBlocks.requiredField');
      }

      if (fieldName === 'buttonLink' && !checkUrl(value)) {
        return t('modalSettingTextBlocks.pleaseCheckIfTheLinkIsCorrect');
      }
      return true;
    };
  };

  const createOnChangeUrlFunction = (onChangeCallBack: (...event: any[]) => void) => {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      const { inputType } = event.nativeEvent as InputEvent;
      if (inputType === 'insertFromPaste') {
        const newVal = correctUrlOnPaste(event)
        onChangeCallBack(newVal);
      } else {
        onChangeCallBack(trimAndReplaceFunction(event.target.value));
      }
    }
  }

  return (
    <>
      <Modal
        name={ModalNames.SettingStory}
        active={active}
        propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
        onClose={onClose}
      >
        <div ref={modalHeaderRef} className={css.header}>
          <Text text={t('modalSettingTextBlocks.textBlock')} mod="title" />
        </div>
        <div className={css.modalInner} ref={modalInnerRef}>
          <div className={css.justifyWrapper}>
            <div className={css.title}>
              <Text
                text={t('modalSettingTextBlocks.blockAccentBackground')}
                mod="title"
                fontSize={12}
                textTransform='uppercase'
              />
              <Button
                icon="info"
                propsStyles={{ padding: 0, background: 'transparent', width: 20, height: 20 }}
                extend={css.infoBtn}
                onClick={openAccentBgModal}
              />
            </div>
            <Toggler
              name="accent-color"
              value="accent-color"
              checked={watch('accentColor')}
              onChange={e => setValue('accentColor', e.target.checked)}
            />
          </div>
          <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={css.wrapper}>
              <Input
                controlled={false}
                {...register('title', { required: t('modalSettingTextBlocks.requiredField') })}
                type="text"
                label={t('modalSettingTextBlocks.title')}
                placeholder={t('modalSettingTextBlocks.enterBlockTitle')}
                propsStyles={{ backgroundColor: theme.background }}
                maxLength={200}
                errorMessage={errors.title?.message}
                labelTextTransform='uppercase'
              />
            </div>
            <div className={css.wrapper}>
              <Textarea
                controlled={false}
                {...register('text', { required: t('modalSettingTextBlocks.requiredField') })}
                label={t('modalSettingTextBlocks.text')}
                placeholder={t('modalSettingTextBlocks.textContentOfTheBlock')}
                propsStyles={{ backgroundColor: theme.background, minHeight: 120 }}
                maxLength={1000}
                errorMessage={errors.text?.message}
                labelTextTransform='uppercase'
              />
            </div>
            <div className={css.justifyWrapper}>
              <Text text={t('modalSettingTextBlocks.button')} mod="title" fontSize={12} textTransform='uppercase' />
              <Toggler
                name="has-button"
                value="has-button"
                checked={hasButtonValue}
                onChange={e => setValue('hasButton', e.target.checked)}
              />
            </div>
            {hasButtonValue && (
              <>
                <div className={css.wrapper} ref={hasButtonWrapperRef}>
                  <Input
                    controlled={false}
                    {...register('buttonText', {
                      validate: validateFieldsIfHasButton('buttonText'),
                    })}
                    type="text"
                    label={t('modalSettingTextBlocks.title')}
                    placeholder={t('modalSettingTextBlocks.enterButtonText')}
                    propsStyles={{ backgroundColor: theme.background }}
                    maxLength={50}
                    errorMessage={errors.buttonText?.message}
                    labelTextTransform='uppercase'
                  />
                </div>
                <div className={css.wrapper}>
                  <Controller
                    control={control}
                    name='buttonLink'
                    rules={{
                      validate: validateFieldsIfHasButton('buttonLink'),
                      maxLength: {
                        value: linkMaxLength,
                        message: tPlural('modalSettingTextBlocks.linkShouldBeLessThanCharacters', linkMaxLength)
                      },
                    }}
                    render={({ field: { name, onChange, value, ref } }) => (
                      <Input
                        ref={ref}
                        name={name}
                        type="text"
                        onChange={createOnChangeUrlFunction(onChange)}
                        value={value}
                        controlled={true}
                        label={t('modalSettingTextBlocks.link')}
                        placeholder={t('modalSettingTextBlocks.buttonWillAppearInTheBlock')}
                        propsStyles={{ backgroundColor: theme.background }}
                        maxLength={1000}
                        labelTextTransform='uppercase'
                        errorMessage={errors.buttonLink?.message}
                        message={
                          <Trans
                            text={t(
                              'modalSettingTextBlocks.weRecommendToUseTeletypeSoUsersDontLeaveYourStore',
                              '<0>',
                              '</0>',
                            )}
                            components={[
                              <a
                                key={1}
                                href="https://teletype.in/"
                                target="_blank"
                                rel="noreferrer"
                              />,
                            ]}
                          />
                        }
                      />
                    )}
                  />
                </div>
              </>
            )}
            {editTextBlock && (
              <div className={css.removeBtnWrapper}>
                <Button
                  text={t('modalSettingTextBlocks.delete')}
                  propsStyles={{ background: colorGrey, color: colorBlack, width: '100%' }}
                  hasGradient={false}
                  onClick={deleteTextBlockFn}
                />
              </div>
            )}

            <div className={css.footer} ref={modalFooterRef}>
              <Button
                disabled={networkStatus === NetworkStatus.Loading}
                text={
                  editTextBlock
                    ? t('modalSettingTextBlocks.save')
                    : t('modalSettingTextBlocks.createBlock')
                }
                propsStyles={{ width: '100%' }}
                type="submit"
              />
            </div>
          </form>
        </div>
      </Modal>
      {renderAccentBgModal && (
        <ModalAccentBg onClose={closeAccentBgModal} active={activeAccentBgModal} />
      )}
      {renderErrorModal && (
        <ModalError
          deep={2}
          onClose={closeErrorModal}
          title={t('modalSettingTextBlocks.error')}
          active={activeErrorModal}
        />
      )}
    </>
  );
});
