import { ChangeEvent, FC, memo, useContext, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { AccentColorRules } from './AccentColor.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { Button } from 'src/components/UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import { useModalState } from 'src/hooks/useModalState';
import { ModalColor } from 'src/components/ModalColor/ModalColor';
import { ThemeManagementContext } from 'src/components/Theme/Theme';
import { hexToRgb } from 'src/utils/hexToRgb';
import { standardColors } from 'src/utils/constants';
import { Spinner } from '../UI/Spinner/Spinner';

interface IAccentColor {
  accentColor: string;
  setAccentColor: (color: string) => void;
}

export const AccentColor: FC<IAccentColor> = memo(function AccentColor(props) {
  const { accentColor, setAccentColor } = props;
  const { css } = useStyle(AccentColorRules);
  const { theme } = useTheme();
  const { setTheme } = useContext(ThemeManagementContext);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [imagesIsLoaded, setImagesIsLoaded] = useState([
    { id: '1', isLoaded: false },
    { id: '2', isLoaded: false },
  ]);
  const [isLoading, setIsLoading] = useState(true);

  const onLoadImage = event => {
    const id = event.currentTarget.id;
    const image = imagesIsLoaded.find(el => el.id === id);
    image.isLoaded = true;
    setImagesIsLoaded(imagesIsLoaded);

    const elementIsLoading = imagesIsLoaded.find(el => el.isLoaded === false);
    if (elementIsLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  };

  const initialColorPickerColor =
    accentColor && standardColors.indexOf(accentColor) === -1 ? accentColor : '';

  const [colorPickerColor, setColorPickerColor] = useState(initialColorPickerColor);

  useEffect(() => {
    const rgbObj = hexToRgb(accentColor);
    const colorRgbMain = `${rgbObj?.r}, ${rgbObj?.g}, ${rgbObj?.b}`;
    setTheme({ ...theme, mainColor: accentColor, colorRgbMain });
  }, [accentColor, setTheme, theme]);

  const onChangeColor = (event: ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    const rgbObj = hexToRgb(accentColor);
    const colorRgbMain = `${rgbObj?.r}, ${rgbObj?.g}, ${rgbObj?.b}`;
    setTheme({ ...theme, mainColor: color, colorRgbMain: colorRgbMain });
    setAccentColor(color);
  };

  const chooseColor = () => {
    setAccentColor(colorPickerColor);
    closeModal();
  };

  return (
    <>
      <div className={css.template}>
        {isLoading && (
          <div className={css.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <div className={css.templateTop}>
          <div className={css.templateButton}>Оформить</div>
          <img src="images/port-template-1.png" alt="" id="1" onLoad={onLoadImage} />
          <Text
            mod="text"
            fontSize={9}
            fontWeight={700}
            lineHeight={10}
            text="30400 "
            extend={css.templatePrice1}
          />
          <Text
            mod="text"
            fontSize={9}
            fontWeight={700}
            lineHeight={10}
            text="- 4000 ₽"
            extend={css.templatePrice2}
          />
        </div>
        <div className={css.templateBottom}>
          <img src="images/port-template-2.png" alt="" id="2" onLoad={onLoadImage} />
          <div className={css.templateButtonSmall}>
            <Icon name="filter" />
          </div>
          <Text
            mod="text"
            text="Женщинам"
            extend={css.templateSubCategory}
            fontSize={7}
            lineHeight={16}
            fontWeight={600}
          />
        </div>
      </div>
      <div className={css.colors}>
        <div className={css.colorsInner}>
          {standardColors.map(color => (
            <label className={css.color} style={{ backgroundColor: color }} key={color}>
              <input
                type="radio"
                name="color"
                value={color}
                checked={color === accentColor}
                onChange={onChangeColor}
              />
              {color === accentColor && (
                <span style={{ backgroundColor: color, filter: 'invert(100%)' }}  />
              )}
            </label>
          ))}
        </div>
        <div className={css.colorPicker}>
          {colorPickerColor && (
            <label className={css.color} style={{ backgroundColor: colorPickerColor }}>
              <input
                type="radio"
                name="color"
                value={colorPickerColor}
                checked={colorPickerColor === accentColor}
                onChange={onChangeColor}
              />
              {colorPickerColor === accentColor && (
                <span style={{ backgroundColor: colorPickerColor, filter: 'invert(100%)' }}  />
              )}
            </label>
          )}

          <Button
            icon="color-picker"
            propsStyles={{
              width: 36,
              height: 36,
              padding: 4,
              background: theme.colorGrey,
            }}
            extend={css.btnColor}
            hasGradient={false}
            onClick={openModal}
          />
        </div>
      </div>
      {renderModal && (
        <ModalColor
          active={activeModal}
          onClose={closeModal}
          setColorPickerColor={setColorPickerColor}
          colorPickerColor={colorPickerColor}
          chooseColor={chooseColor}
        />
      )}
    </>
  );
});
