import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { uploadFiles } from '../api/products/uploadFiles';
import { upsertProduct } from '../api/products/upsertProduct';
import { createProduct } from '../api/products/createProduct';

interface IUploadedFilesSlice{
  networkStatus: NetworkStatus;
  uploadedFilesUrls: string[]
}

const initialState: IUploadedFilesSlice= {
  networkStatus: NetworkStatus.None,
  uploadedFilesUrls: []
};

export const uploadedFilesSlice = createSlice({
  name: 'uploadedFiles',
  initialState,
  reducers: {
    resetUploadedFilesNetworkStatus: state => {
      state.networkStatus = NetworkStatus.None
    }
  },
  extraReducers: builder => {
    builder.addCase(uploadFiles.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(uploadFiles.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    builder.addCase(uploadFiles.fulfilled, (state, action) => {
      state.networkStatus = NetworkStatus.Done;
      state.uploadedFilesUrls = Array.from(
        new Set(state.uploadedFilesUrls.concat(action.payload))
      );
    });
    builder.addCase(upsertProduct.fulfilled, state => {
      state.networkStatus = NetworkStatus.None;
      state.uploadedFilesUrls = []
    });
    builder.addCase(createProduct.fulfilled, state => {
      state.networkStatus = NetworkStatus.None;
      state.uploadedFilesUrls = []
    });
  }
});

export const { resetUploadedFilesNetworkStatus } = uploadedFilesSlice.actions;

export const uploadedFilesState = (state: RootState) => state[uploadedFilesSlice.name];
