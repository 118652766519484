import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { getSliderList } from '../api/slider/getSliderList';
import { createSlider } from '../api/slider/createSlider';
import { ISlider } from 'src/types/slider';
import { sliderUpsert } from '../api/slider/sliderUpsert';

interface IStoriesSlice {
  slider: ISlider | null;
  networkStatus: NetworkStatus;
  upsertNetworkStatus: NetworkStatus,
}

const initialState: IStoriesSlice = {
  slider: null,
  networkStatus: NetworkStatus.None,
  upsertNetworkStatus: NetworkStatus.None,
};

export const sliderSlice = createSlice({
  name: 'Slider',
  initialState,
  reducers: {
    updateSlider: (state, { payload }: PayloadAction<ISlider>) => {
      state.slider = payload;
    },
    removeSlide: (state, { payload }: PayloadAction<string>) => {
      state.slider.items = state.slider.items.filter(item => item.id !== payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(getSliderList.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getSliderList.fulfilled, (state, action) => {
      state.slider = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getSliderList.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(sliderUpsert.pending, state => {
      state.upsertNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(sliderUpsert.fulfilled, state => {
      state.upsertNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(sliderUpsert.rejected, state => {
      state.upsertNetworkStatus = NetworkStatus.Failed;
    });

    // createSlider
    builder.addCase(createSlider.fulfilled, (state, { payload }) => {
      state.slider = payload;
    });
  },
});

export const { removeSlide, updateSlider } = sliderSlice.actions;

export const SliderState = (state: RootState) => state[sliderSlice.name];
