import { hexRegex } from '../hexToRgb';
import { IConfig, IRemoteConfig, Toggles } from './config.interface';
import { checkUrl } from '../checkUrl';

export async function getConfig(): Promise<IConfig> {
  if (process.env.NODE_ENV === 'development') {
    return {
      hubApiUrl: 'https://mars-api.apps.teleportapp.store',
      fsApiUrl: 'https://fs-api.apps.teleportapp.store',
      billingApiUrl: 'https://mars-api.apps.teleportapp.store',
      accentColor: '#3390ec',
      hubBotUsername: 'TLPRT1HUB_Bot',
      portBotUsername: 'TLPRT999A_Bot',
      toggles: {
        [Toggles.subscriptionEnabled]: true,
        [Toggles.loyaltyEnabled]: false,
        [Toggles.cartEnabled]: true,
        [Toggles.multipleStoreEnabled]: true,
        [Toggles.newsletterEnabled]: true,
        [Toggles.statisticsEnabled]: true
      },
    };
  }

  try {
    const response = await fetch('/config.json');
    const config: IRemoteConfig = await response.json();

    // apiurl - валидный урл
    // accentColor - валдиный hex
    // language - валидный iso код языка

    if (!checkUrl(config.router['hub-api']) || !checkUrl(config.router['fs-api'])) {
      console.error('invalid hub or fs url');

      return {};
    }

    return {
      hubApiUrl: config.router['hub-api'],
      fsApiUrl: config.router['fs-api'],
      billingApiUrl: config.router['billing-api'],
      accentColor: hexRegex.test(config.accentColor) ? config.accentColor : '#3390ec',
      hubBotUsername: config.hubBotUsername,
      portBotUsername: config.portBotUsername,
      toggles: config.toggles ?? {} as Record<Toggles, boolean>
    };
  } catch (error) {
    console.error('Could not load config', error);

    return null;
  }
}
