const ymId = 98743756;

export class Analytics {
  static #instance: Analytics;

  private constructor() {
    // do nothing.
  }

  public static get instance(): Analytics {
    if (!Analytics.#instance) {
      Analytics.#instance = new Analytics();
    }

    return Analytics.#instance;
  }

  private send(event: string) {
    if (window['ym']) {
      window['ym'](ymId, 'reachGoal', event);
    }
  }

  public appStart() {
    this.send('appStart');
  }

  public wizardStart() {
    this.send('wizardStart');
  }

  public storeCreating() {
    this.send('storeCreating');
  }

  public firstImageUploaded() {
    this.send('firstImageUploaded');
  }

  public firstProductCreated() {
    this.send('firstProductCreated');
  }
}

export const analytics = Analytics.instance;
