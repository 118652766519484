import { PromiseClient } from '@connectrpc/connect/dist/cjs/promise-client';
import { BillingService } from '@teleport/schemas-protobuf-billing-v1';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { createPromiseClient } from '@connectrpc/connect';
import { headersAuthInterceptor } from './interceptors/headersAuthInterceptor';
import { statusCodeInterceptor } from './interceptors/statusCodeInterceptor';

export const billingApi = (baseUrl: string): PromiseClient<typeof BillingService> => {
  const transport = createGrpcWebTransport({
    baseUrl,
    useBinaryFormat: true,
    binaryOptions: {
      readUnknownFields: true,
    },
    interceptors: [headersAuthInterceptor, statusCodeInterceptor],
  });

  return createPromiseClient(BillingService, transport);
};
