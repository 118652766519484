export interface ITheme {
  background: string;
  colorBlack: string;
  mainColor: string;
  colorWhite: string;
  colorRed: string;
  colorGrey: string;
  colorDarkGrey: string;
  colorGreenSuccess: string;
  colorBlueDark: string;
  colorDarkGreen: string;
  gradientBlue: string;
  colorPink: string;
  colorBlue: string;
  colorGreen: string;
  colorGreenDark: string;
  colorGreenLight: string;
  colorOrange: string;
  colorOrangeLight: string;
  colorBlueLight:string;
  colorRedLight: string;
  colorPurple: string;
  colorPurpleLight: string;
  colorGray: string;
  colorGrayLight: string;
  colorYellow: string;

  // rgb
  colorRgbMain: string;
  colorRgbRed: string;
  colorRgbWhite: string;
  colorRgbBlack: string;

  appMaxWidth: number;
  sidePadding: number;
  appFooterHeight: number;
  defaultFont: string;
  transitionDuration: string;
}

export enum ThemeName {
  Dark = 'dark',
  Light = 'light',
}
