import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IModalStoreCreatedResultRuleKeys {
  modalInner: {};
  footer: {};
}

interface IModalStoreCreatedResultProps {
  footerHeight: number;
}

export type IModalStoreCreatedResultRules = IRuleFn<
  IModalStoreCreatedResultRuleKeys,
  IModalStoreCreatedResultProps
>;

const ModalStoreCreatedResultRuleFn: IModalStoreCreatedResultRules = props => {
  const { footerHeight } = props;
  const { sidePadding, colorWhite } = props.theme;
  return {
    modalInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      maxHeight: '100%',
      overflow: 'auto',
      paddingTop: 50,
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      paddingBottom: `calc(${footerHeight}px + 54px)`,
      textAlign: 'center',
    },
    footer: {
      ...padding(12,sidePadding, 30),
      width: '100%',
      backgroundColor: colorWhite,
      position: 'fixed',
      bottom: 0,
      display: 'flex',
      columnGap: '12px',
    },
  };
};

export const ModalStoreCreatedResultRules = createCachedStyle(ModalStoreCreatedResultRuleFn);
