import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ITextRuleKeys {
  text: {};
  img: {};
}

interface ITextRuleProps {
  mod: 'title' | 'text';
  letterSpacing;
  lineHeight;
  textTransform;
  fontWeight;
  fontSize;
  whiteSpace?: 'pre-wrap' | 'nowrap';
  display?: string;
}

export type ITextRules = IRuleFn<ITextRuleKeys, ITextRuleProps>;

const textRuleFn: ITextRules = props => {
  const {
    mod,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
    textTransform,
    whiteSpace = 'pre-wrap',
    display
  } = props;

  return {
    text: {
      fontSize: fontSize ? fontSize : mod === 'title' ? 20 : 16,
      fontWeight: fontWeight ? fontWeight : mod === 'title' ? 800 : 400,
      letterSpacing: letterSpacing ?? -0.4,
      lineHeight: lineHeight ? lineHeight : mod === 'title' ? 26 : 'normal',
      textTransform: textTransform ?? 'initial',
      whiteSpace,
      display: display ?? 'block',
      alignItems: display ? 'center' : 'normal',
    },
    img: {
      width: fontSize? fontSize * 1.42 :  mod === 'title' ? 20 * 1.42 : 16 * 1.42
    }
  };
};

export const textRules = createCachedStyle(textRuleFn);
